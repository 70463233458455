import { ArrowRightIcon, PencilIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { IActionItem } from '../../../shared/model/IActionItem';
import { useboardPriviligesQueries } from '../queries/boardPriviligesQueries';
import useModals from '../../../shared/components/modal/hooks/useModal';
import { ResourceType } from '../../../shared/model/ResourceType';
import InviteMember from '../../Members/MemberInvite';
import BoardEdit from '../BoardEdit';
import useConfirm from '../../../shared/components/alert-dialog/hooks/useConfirm';
import { ButtonColors } from '../../../shared/constants/ButtonColors';
import { useDeleteBoardMutation } from '../queries/boardQueries';
import { useRemoveMemberMutation } from '../../Members/queries/MemberQueries';
import { useMyUserId } from '../../../shared/auth/accountHooks';

export default function useBoardActions(
  projectId?: string,
  boardId?: string,
  onDeleteCallback?: () => void,
  onLeaveCallback?: () => void,
) {
  const privilegesQuery = useboardPriviligesQueries(projectId, boardId);
  const privileges = privilegesQuery.data;

  const myUserId = useMyUserId();

  const deleteMutation = useDeleteBoardMutation();
  const removeMemberMutation = useRemoveMemberMutation(ResourceType.Board);

  const { openModal, closeModal } = useModals();
  const { openConfirm, closeConfirm } = useConfirm();

  const menuItems: IActionItem[] = [];
  if (privileges?.canInviteMember && projectId && boardId) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () =>
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              onComplete={() => closeModal()}
              resourceIds={[projectId, boardId]}
              type={ResourceType.Board}
            />
          ),
        }),
    });
  }
  if (privileges?.canEdit && projectId && boardId) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () =>
        openModal({
          title: 'Edit board',
          content: (
            <BoardEdit
              projectId={projectId}
              boardId={boardId}
              onOpenChange={closeModal}
              onSuccess={closeModal}
            />
          ),
        }),
    });
  }
  if (privileges?.canDelete && projectId && boardId) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () =>
        openConfirm({
          title: 'Delete board',
          description: 'Are you sure you want to delete this board?',
          actionText: 'Delete',
          actionColor: ButtonColors.Red,
          onAction: () => {
            deleteMutation.mutate({ projectId, boardId });
            closeConfirm();
            if (onDeleteCallback) onDeleteCallback();
          },
        }),
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () =>
        openConfirm({
          title: 'Leave board',
          description: 'Are you sure you want to leave this board?',
          actionText: 'Leave',
          actionColor: ButtonColors.Red,
          onAction: () => {
            if (!myUserId || !projectId || !boardId) {
              closeModal();
              return;
            }

            removeMemberMutation.mutate(
              {
                member: {
                  name: '',
                  resourceId: [projectId, boardId],
                  userId: myUserId,
                },
              },
              {
                onSuccess: () => {
                  closeConfirm();
                  if (onLeaveCallback) onLeaveCallback();
                },
              },
            );
          },
        }),
    });
  }

  return { items: menuItems, isPending: privilegesQuery.isPending };
}
