import { ChangeEvent, useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Button from '../../shared/components/buttons/Button';
import SelectList from '../../shared/components/lists/SelectList';
import ModalLegacy, { ModalSize } from '../../shared/components/modal/ModalLegacy';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SearchBar from '../../shared/components/search/SearchBar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import useDebounce from '../../shared/hooks/useDebounce';
import { userProfileQueryBase } from '../Profile/queries/userProfileQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import { DialogFooter } from '../../shared/components/dialog/Dialog';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';
import { useSearchAssignPagesQuery } from './queries/activitiesQueries';

export interface IListItem {
  id: string;
  headline: string;
  information: string;
  photoUrl: string;
}

function AssignToSelectList({
  resourceIds,
  resourceType,
  activityId,
  searchTerm,
  selectedItem,
  onSelectItem,
}: {
  resourceIds: ResourceIds;
  resourceType: ResourceType;
  activityId: string;
  searchTerm: string;
  selectedItem?: IListItem;
  onSelectItem: (item: IListItem) => void;
}) {
  const queryClient = useQueryClient();
  const cleanedSearchTerm = searchTerm.toLocaleLowerCase().trim();
  const resourceMembersQuery = useSearchAssignPagesQuery(
    resourceType,
    resourceIds,
    activityId,
    (member) => member.displayName.toLocaleLowerCase().indexOf(cleanedSearchTerm) >= 0,
  );

  const [listItems, setListItems] = useState<IListItem[]>();
  useEffect(() => {
    async function GetListItems() {
      if (!resourceMembersQuery.data) {
        return;
      }

      const result: IListItem[] = await Promise.all(
        resourceMembersQuery.data.map(async (member) => {
          const userProfile = await queryClient.fetchQuery(userProfileQueryBase(member.id));
          return {
            id: member.id,
            headline: `${userProfile.displayName}`,
            information: userProfile.primaryEmailAddress,
            photoUrl: userProfile.photoUrl,
          };
        }),
      );
      setListItems(result);
    }

    GetListItems();
  }, [resourceMembersQuery.data]);

  if (listItems) {
    return (
      <SelectList
        data={listItems}
        isLoading={false}
        onSelectItem={onSelectItem}
        selectedItem={selectedItem}
        itemIdProperty={({ id }) => id}
        itemHeadlineProperty={({ headline }) => headline}
        itemInformationProperty={({ information }) => information}
        itemImgSrcProperty={({ photoUrl }) => photoUrl}
      />
    );
  }

  return null;
}

interface IProps {
  resourceIds: ResourceIds;
  resourceType: ResourceType;
  activityId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  isPending: boolean;
  onAssign: (result: IListItem) => void;
}

export default function ActivityAssignToModal({
  resourceIds,
  activityId,
  resourceType,
  open,
  isPending,
  onOpenChange,
  onAssign,
}: IProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAssignee, setSelectedAssignee] = useState<IListItem | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleOnSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
    setSelectedAssignee(undefined);
  };

  const handleOpenChange = (_open: boolean) => {
    if (!_open) {
      setSelectedAssignee(undefined);
    }
    onOpenChange(_open);
  };

  const handleAssign = () => {
    if (!resourceIds || !activityId) {
      return;
    }
    if (!selectedAssignee) return;
    onAssign(selectedAssignee);
    handleOpenChange(false);
  };
  return (
    <ModalLegacy open={open} onOpenChange={handleOpenChange} modalSize={ModalSize.Small}>
      <div className="mt-6">
        <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
      </div>
      <ScrollBarWrapper>
        <div className="max-h-96 text-left">
          <AssignToSelectList
            resourceIds={resourceIds}
            resourceType={resourceType}
            activityId={activityId}
            searchTerm={debouncedSearchTerm}
            selectedItem={selectedAssignee}
            onSelectItem={setSelectedAssignee}
          />
        </div>
      </ScrollBarWrapper>
      <DialogFooter>
        <Button
          color={ButtonColors.Blue}
          text="Assign"
          onClick={handleAssign}
          disabled={!selectedAssignee || isPending}
        />
      </DialogFooter>
    </ModalLegacy>
  );
}
