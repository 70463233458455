import {
  InfiniteData,
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useDispatchApiError } from '../../../shared/hooks/useDispatchApiError';
import {
  IUpdateActivityDto,
  blockActivity,
  completeActivity,
  createActivity,
  deleteActivity,
  getActivities,
  moveActivityTask,
  pauseActivity,
  searchAssign,
  setAssignedToOnActivity,
  setExpirationOnActivity,
  setReminderOnActivity,
  startActivity,
  updateActivity,
} from '../../../shared/services/activitiesService';
import {
  ActivityStatus,
  ActivityType,
  IActivityDto,
  IActivityUserDto,
} from '../../../shared/model/IActitityDto';

import { CalculateDragDropPositions } from '../../../shared/utils/dragDropUtils';
import { useInfiniteApiQuery } from '../../../shared/hooks/useInfiniteApiQuery';
import { IPagedResult } from '../../../shared/model/IPagedResult';
import ConvertFlatListToInfiniteDataPages from '../../../shared/utils/pageUtils';
import { IMoveActivityDto } from '../../../shared/model/IMoveActivityDto';
import { updateInfiniteQueriesData } from '../../../shared/utils/queryClientUtils';
import { ResourceType } from '../../../shared/model/ResourceType';
import { ResourceIds } from '../../../shared/hooks/useEntityManifest';

interface IUseActivitiesProps {
  resourceIds: ResourceIds;
}

export const activityKeys = {
  all: (type: ResourceType) => ['activities', type] as const,
  lists: (type: ResourceType) => [...activityKeys.all(type), 'list'] as const,
  list: (type: ResourceType, props: IUseActivitiesProps) =>
    [...activityKeys.all(type), 'list', props] as const,
  detail: (type: ResourceType, id: string) => [...activityKeys.all(type), 'detail', id] as const,
  listAssign: (type: ResourceType, id: string) => [type, 'listAssign', id],
};

const limit = 50;
export function useActivitiesPagesQuery(resourceIds: ResourceIds, entityType: ResourceType) {
  return useInfiniteApiQuery({
    queryKey: activityKeys.list(entityType, { resourceIds }),
    queryFn: () => getActivities(resourceIds, entityType, limit),
  });
}

export function useSearchAssignPagesQuery(
  entityType: ResourceType,
  resourceIds: ResourceIds,
  activityId: string,
  filter: (member: IActivityUserDto) => boolean,
) {
  return useQuery({
    queryKey: activityKeys.listAssign(entityType, activityId),
    queryFn: () => searchAssign(resourceIds, entityType, activityId, limit),
    select: (members: IPagedResult<IActivityUserDto>) => members.data.filter(filter),
  });
}

function updateCachedActivity<TVariables extends { activityId: string }>(
  queryClient: QueryClient,
  variables: TVariables,
  resourceType: ResourceType,
  keyProps: IUseActivitiesProps,
  transform: (activity: IActivityDto, variables: TVariables) => IActivityDto,
) {
  const updateFunc = (data: IActivityDto[]) => {
    const updatedData = data.map((activity) => {
      if (activity.id === variables.activityId) {
        return transform(activity, variables);
      }
      return activity;
    });

    return updatedData;
  };

  updateInfiniteQueriesData<IActivityDto>(
    queryClient,
    activityKeys.list(resourceType, { resourceIds: keyProps.resourceIds }),
    updateFunc,
  );
}

function useOptimisticUpdateProps<TVariables extends { activityId: string }>(
  resourceType: ResourceType,
  keyProps: IUseActivitiesProps,
  transform: (activity: IActivityDto, variables: TVariables) => IActivityDto,
) {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return {
    onMutate: (variables: TVariables) =>
      updateCachedActivity(queryClient, variables, resourceType, keyProps, transform),
    onSuccess: (_: unknown, variables: TVariables) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list(resourceType, keyProps) });
      queryClient.invalidateQueries({
        queryKey: activityKeys.detail(resourceType, variables.activityId),
      });
    },
    onError: (error: unknown, _: unknown, rollback: unknown) => {
      if (typeof rollback === 'function') rollback();
      dispatchError(error);
    },
  };
}

export function useListActivitiesQuery(resourceIds: ResourceIds, entityType: ResourceType) {
  return useQuery({
    queryKey: activityKeys.list(entityType, { resourceIds }),
    queryFn: () => getActivities(resourceIds, entityType),
  });
}

export function useDeleteActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  const queryClient = useQueryClient();
  const dispatchError = useDispatchApiError();
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      deleteActivity(resourceIds, entityType as ResourceType, activityId),

    onSuccess: (_, { activityId }) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list(entityType, { resourceIds }) });
      queryClient.removeQueries({ queryKey: activityKeys.detail(entityType, activityId) });
    },
    onError: (error, _, rollback) => {
      if (typeof rollback === 'function') rollback();
      dispatchError(error);
    },
  });
}

interface IEditActivityProps {
  activityId: string;
  title?: string;
  text?: string;
}

export function useEditActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  return useMutation({
    mutationFn: ({ activityId, title, text }: IEditActivityProps) => {
      const updateActivityDto: IUpdateActivityDto = {
        title: title || undefined,
        text: text || undefined,
      };
      return updateActivity(resourceIds, entityType, activityId, updateActivityDto);
    },
    ...useOptimisticUpdateProps<IEditActivityProps>(
      entityType,
      { resourceIds },
      (activity, { title, text }) => ({
        ...activity,
        title: title || activity.title,
        text: text || activity.text,
      }),
    ),
  });
}

export function usePauseActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      pauseActivity(resourceIds, entityType as ResourceType, activityId),
    ...useOptimisticUpdateProps(entityType, { resourceIds }, (activity) => ({
      ...activity,
      status: ActivityStatus.Pending,
    })),
  });
}

export function useCompleteActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      completeActivity(resourceIds, entityType as ResourceType, activityId),
    ...useOptimisticUpdateProps(entityType, { resourceIds }, (activity) => ({
      ...activity,
      status: ActivityStatus.Completed,
    })),
  });
}

export function useStartActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      startActivity(resourceIds, entityType as ResourceType, activityId),
    ...useOptimisticUpdateProps(entityType, { resourceIds }, (activity) => ({
      ...activity,
      status: ActivityStatus.InProgress,
    })),
  });
}

export function useBlockActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  return useMutation({
    mutationFn: ({ activityId }: { activityId: string }) =>
      blockActivity(resourceIds, entityType as ResourceType, activityId),
    ...useOptimisticUpdateProps(entityType, { resourceIds }, (activity) => ({
      ...activity,
      status: ActivityStatus.Blocked,
    })),
  });
}

interface ISetReminderOnActivityProps {
  activityId: string;
  remindAtTime: string;
}

export function useReminderOnActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  return useMutation({
    mutationFn: ({ activityId, remindAtTime }: ISetReminderOnActivityProps) =>
      setReminderOnActivity(resourceIds, entityType as ResourceType, activityId, remindAtTime),
    ...useOptimisticUpdateProps<ISetReminderOnActivityProps>(
      entityType,
      { resourceIds },
      (activity, { remindAtTime }) => ({
        ...activity,
        reminderTime: remindAtTime,
      }),
    ),
  });
}

interface ISetExpirationOnActivityProps {
  activityId: string;
  expireAtTime: string;
}

export function useExpirationOnActivityMutation(
  resourceIds: ResourceIds,
  entityType: ResourceType,
) {
  return useMutation({
    mutationFn: ({ activityId, expireAtTime }: ISetExpirationOnActivityProps) =>
      setExpirationOnActivity(resourceIds, entityType as ResourceType, activityId, expireAtTime),
    ...useOptimisticUpdateProps<ISetExpirationOnActivityProps>(
      entityType,
      { resourceIds },
      (activity, { expireAtTime }) => ({
        ...activity,
        expireTime: expireAtTime,
      }),
    ),
  });
}

interface ICreateActivityProps {
  title: string;
  type: ActivityType;
}

export function useCreateActivityMutation(resourceIds: ResourceIds, entityType: ResourceType) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ title, type }: ICreateActivityProps) =>
      createActivity(resourceIds, entityType, title, type, false),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list(entityType, { resourceIds }) });
    },
    onError: useDispatchApiError(),
  });
}

interface ISetAssignedToOnActivityProps {
  activityId: string;
  userId: string;
}

export function useSetAssignedToOnActivityMutation(
  resourceIds: ResourceIds,
  entityType: ResourceType,
) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ activityId, userId }: ISetAssignedToOnActivityProps) =>
      setAssignedToOnActivity(resourceIds, entityType, activityId, userId),
    onSuccess: (_, { activityId }) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list(entityType, { resourceIds }) });
      queryClient.invalidateQueries({ queryKey: activityKeys.detail(entityType, activityId) });
    },
    onError: useDispatchApiError(),
  });
}

interface IMoveActivityProps {
  resourceIds: ResourceIds;
  entityType: ResourceType;
  activityId: string;
  destinationIndex: number;
  activityMove: IMoveActivityDto;
}

export function useMoveActivityMutation() {
  const dispatchError = useDispatchApiError();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ resourceIds, entityType, activityId, activityMove }: IMoveActivityProps) =>
      moveActivityTask(resourceIds, entityType, activityId, activityMove),
    onMutate: ({ resourceIds, entityType, activityId, destinationIndex }) => {
      const activities = queryClient.getQueryData(
        activityKeys.list(entityType, { resourceIds }),
      ) as InfiniteData<IPagedResult<IActivityDto>>;

      const activitiesData = activities?.pages.flatMap((i) => i.data)?.flat() as IActivityDto[];

      const { targetItems } = CalculateDragDropPositions<IActivityDto>(
        activitiesData,
        activitiesData,
        activityId,
        destinationIndex,
      );

      activities.pages = ConvertFlatListToInfiniteDataPages<IActivityDto>(
        activities,
        targetItems,
        limit,
      );

      queryClient.setQueryData(activityKeys.list(entityType, { resourceIds }), activities);

      return { activities };
    },
    onSuccess: (_, { activityId, resourceIds, entityType }) => {
      queryClient.invalidateQueries({ queryKey: activityKeys.list(entityType, { resourceIds }) });
      queryClient.invalidateQueries({ queryKey: activityKeys.detail(entityType, activityId) });
    },
    onError: (error, { entityType, resourceIds }, oldData) => {
      if (oldData) {
        queryClient.setQueryData(activityKeys.list(entityType, { resourceIds }), oldData);
      }
      dispatchError(error);
    },
  });
}
