import { useState } from 'react';
import { PencilIcon, RefreshCcw, RefreshCwOff, UserPlusIcon } from 'lucide-react';
import { usePipelineStoreContext } from './context/pipeline-context';
import { usePipelineItemQuery } from './queries/itemQueries';
import SidePanelHeadline from '../../shared/components/layout/building-blocks/SidePanelHeadline';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import Text from '../../shared/components/text/Text';
import { useShareResourceMutation } from './queries/pipelineSharedResourcesQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import HorizontalDivider from '../../shared/components/dividers/HorizontalDivider';
import { useMyUserId } from '../../shared/auth/accountHooks';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import { usePipelineQuery } from './queries/pipelineQueries';
import Header2 from '../../shared/components/headers/Header2';
import Header3 from '../../shared/components/headers/Header3';
import { swedishCurrencyFormatter } from '../../shared/utils/numberUtils';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import useModals from '../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../shared/model/IActionItem';
import PipelineEditItemForm from './PipelineEditItemForm';
import ProfileAvatarStatus from '../../shared/components/avatar/ProfileAvatarStatus';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../shared/components/drop-down-menu/DropdownMenu';
import { renderDropdownMenuItems } from '../../shared/components/drop-down-menu/utils';
import ContactCreateForm, { useContactCreateForm } from '../Contacts/ContactCreateForm';
import Alert from '../../shared/components/alerts/Alert';
import { IContactDetailDto, IContactDto } from '../Contacts/models/IContactDto';
import { useContactsQuery } from '../Contacts/queries/contactQueries';
import useUserMenuActions from '../Contacts/hooks/useUserMenuActions';
import { IPipelineItemDto } from './models/IPipelineItemDto';
import { Card, CardContent, CardHeader } from '../../shared/components/card/Card';
import { ProfilePropertyList } from '../Profile/ProfilePropertyList';
import { NetworkPropertyList } from '../Networks/NetworkPropertyList';
import Activities from '../Activities/Activities';

interface IContactCreateFormWrapperProps {
  entity: IContactDto;
}

function getEmailAddressIntersection(contacts: IContactDto[], emailAddresses: IContactDetailDto[]) {
  const foundEmailAddresses = contacts
    .map((contact) => contact.emailAddresses.map((email) => email.value))
    .flat();
  return emailAddresses
    .map((email) => email.value)
    .filter((email) => foundEmailAddresses.includes(email));
}

function ContactCreateFormWrapper({ entity }: IContactCreateFormWrapperProps) {
  const { closeModal } = useModals();
  const formObject = useContactCreateForm({ values: entity || {} });

  const findContactsQuery = useContactsQuery({
    searchTerm: entity.emailAddresses?.map((ea) => ea.value)?.join(' '),
    searchFields: ['EmailAddress'],
    missingSubscriptionOfType: 'InternalUser',
  });

  const duplicateEmailAddresses =
    (!!findContactsQuery.data &&
      getEmailAddressIntersection(findContactsQuery.data, entity.emailAddresses ?? [])) ||
    [];

  return (
    <>
      {duplicateEmailAddresses.length > 0 && (
        <Alert
          type="Warning"
          text={`You already have a contact or contacts with the email address(es) ${duplicateEmailAddresses.join(
            ', ',
          )}, a new contact will still be created if you proceed.`}
        />
      )}
      <ContactCreateForm onSuccess={closeModal} onCancel={closeModal} form={formObject} />
    </>
  );
}

interface IDropdownMenuContentWrapperProps {
  pipelineItem: IPipelineItemDto;
}

function DropdownMenuContentWrapper({ pipelineItem }: IDropdownMenuContentWrapperProps) {
  const userId = useMyUserId();
  const { openModal } = useModals();

  const sharingMutation = useShareResourceMutation();
  const { activePipelineSettings } = usePipelineStoreContext();

  const pipelineItemQuery = usePipelineItemQuery({
    pipelineId: activePipelineSettings.pipelineId,
    stageId: activePipelineSettings.stageId,
    itemId: activePipelineSettings.item.id,
  });

  const socialContactActions = useUserMenuActions(pipelineItem.sharedResource?.payload?.userId);

  const useCopyContactActions =
    (pipelineItem.sharedResource?.resourceType === ResourceType.ContactPassive ||
      pipelineItem.sharedResource?.resourceType === ResourceType.Contact) &&
    !pipelineItem.sharedResource?.payload?.userId;

  const useSocialContactActions = socialContactActions.menuItems.length > 0;

  const useDivider = useCopyContactActions || useSocialContactActions;

  const stopSharingAction: IActionItem = {
    id: 'stopSync',
    title: 'Stop sync',
    icon: RefreshCwOff,
    useDivider,
    onClick: () => {
      if (!pipelineItem.sharedResource) {
        return;
      }

      sharingMutation.mutate(
        {
          pipelineId: activePipelineSettings.pipelineId,
          request: {
            isLiveUpdated: false,
            resourceId: pipelineItem.sharedResource.resourceId,
            type: pipelineItem.sharedResource.resourceType,
          },
        },
        {
          onSuccess: () => {
            pipelineItemQuery.refetch();
          },
        },
      );
    },
  };

  const startSharingAction: IActionItem = {
    id: 'startSync',
    title: 'Start sync',
    icon: RefreshCcw,
    useDivider,
    onClick: () => {
      if (!pipelineItem.sharedResource) {
        return;
      }
      sharingMutation.mutate(
        {
          pipelineId: activePipelineSettings.pipelineId,
          request: {
            isLiveUpdated: true,
            resourceId: pipelineItem.sharedResource.resourceId,
            type: pipelineItem.sharedResource.resourceType,
          },
        },
        {
          onSuccess: () => {
            pipelineItemQuery.refetch();
          },
        },
      );
    },
  };

  const avatarActions: IActionItem[] = [];
  if (
    pipelineItem.sharedResource &&
    pipelineItem.sharedResource.isLiveUpdated &&
    pipelineItem.sharedResource.sharedBy === userId
  ) {
    avatarActions.push(stopSharingAction);
  }

  if (
    pipelineItem.sharedResource &&
    !pipelineItem.sharedResource.isLiveUpdated &&
    pipelineItem.sharedResource.sharedBy === userId
  ) {
    avatarActions.push(startSharingAction);
  }

  const copyContactActions: IActionItem = {
    id: 'copy',
    title: 'Add to my contacts',
    icon: UserPlusIcon,
    onClick: () =>
      openModal({
        title: '',
        content: <ContactCreateFormWrapper entity={pipelineItem.sharedResource?.payload} />,
      }),
  };

  if (useCopyContactActions) {
    avatarActions.push(copyContactActions);
  }

  if (useSocialContactActions) {
    avatarActions.push(...socialContactActions.menuItems);
  }

  return <DropdownMenuContent>{renderDropdownMenuItems(avatarActions)}</DropdownMenuContent>;
}

export default function PipelineEditItem() {
  const { activePipelineSettings } = usePipelineStoreContext();
  const [activeTab, setActiveTab] = useState('tab_info');
  const { openModal, closeModal } = useModals();
  const { pipelineId } = activePipelineSettings;
  const pipelineItemQuery = usePipelineItemQuery({
    pipelineId,
    stageId: activePipelineSettings.stageId,
    itemId: activePipelineSettings.item.id,
  });

  const pipeline = usePipelineQuery(pipelineId);

  const pipelineItem = pipelineItemQuery.data;
  const resourceType = pipelineItem?.sharedResource?.resourceType || ResourceType.PipelineItem;
  const resourceManifest = useEntityManifest(resourceType);

  if (!pipelineItemQuery.isSuccess || !pipelineItem) {
    return <></>;
  }

  const { stageId } = pipelineItem;
  const itemId = pipelineItem.id;
  const itemMenu: IActionItem[] = [];

  itemMenu.push({
    id: 'Edit',
    title: 'Edit',
    icon: PencilIcon,
    onClick: () => {
      openModal({
        title: 'Edit',
        content: (
          <PipelineEditItemForm
            pipelineItem={pipelineItem}
            activePipelineSettings={activePipelineSettings}
            onSaveCallback={closeModal}
          />
        ),
      });
    },
  });

  const renderHeader = () => (
    <FlexContainer direction="row" gap="small" align="stretch" justify="between">
      <FlexContainer>
        <DropdownMenu>
          <DropdownMenuTrigger>
            <ProfileAvatarStatus
              profileAvatarProps={{
                avatarProps: {
                  src: pipelineItem.sharedResource?.payload?.photoUrl || resourceManifest.icon,
                  widthClass: 'w-6',
                  heightClass: 'h-6',
                },
              }}
              statusProps={{
                status: pipelineItem.sharedResource?.isLiveUpdated ? 'on' : 'off',
                tooltipMessage: pipelineItem.sharedResource?.isLiveUpdated
                  ? 'Sync with source is on'
                  : 'Sync with source is off',
              }}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContentWrapper pipelineItem={pipelineItem} />
        </DropdownMenu>
        <Header3>{pipelineItem.sharedResource?.payload?.displayName}</Header3>
      </FlexContainer>
    </FlexContainer>
  );

  return (
    <>
      <SidePanelHeadline
        headline={pipelineItem?.name}
        imageSrc={resourceManifest.icon}
        resourceId={pipelineItem?.sharedResource?.resourceId}
        resourceLink={`/${resourceManifest.endpointName}/${pipelineItem?.sharedResource?.resourceId}`}
        showLinkToResource={!!pipelineItem?.sharedResource}
        colorSetting={resourceManifest.color}
      />
      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="tab_info"
            activeId={activeTab}
            title="Info"
            setActiveTab={() => setActiveTab('tab_info')}
          />
          <TabNavItem
            id="tab_activities"
            activeId={activeTab}
            title="Tasks"
            setActiveTab={() => setActiveTab('tab_activities')}
          />
          <TabDropdownItem isActive={false} menuItems={itemMenu} />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="tab_info" activeTabId={activeTab}>
            <FlexContainer direction="column" gap="small" align="stretch">
              <FlexContainer direction="column" gap="small" align="start">
                <Header2>Overview</Header2>
                <Text size="small">{pipelineItem?.description}</Text>

                <HeaderSubHeader
                  text={`Price (${pipeline.data?.currencyIsoCode})`}
                  size="small"
                  subText={swedishCurrencyFormatter(pipelineItem?.value)}
                />
              </FlexContainer>
              <HorizontalDivider />

              {pipelineItem.sharedResource && (
                <>
                  <Header2>Connected Resources</Header2>
                  <Card>
                    <CardHeader>{renderHeader()}</CardHeader>
                    <CardContent>
                      {pipelineItem.sharedResource?.resourceType === ResourceType.Network ? (
                        <NetworkPropertyList network={pipelineItem.sharedResource?.payload} />
                      ) : (
                        <ProfilePropertyList profile={pipelineItem.sharedResource?.payload} />
                      )}
                    </CardContent>
                  </Card>
                </>
              )}
            </FlexContainer>
          </TabContent>
          <TabContent id="tab_activities" activeTabId={activeTab}>
            <Activities
              resourceIds={[pipelineId, stageId, itemId]}
              type={ResourceType.PipelineItem}
            />
          </TabContent>
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
