import clsx from 'clsx';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ButtonColors } from '../../constants/ButtonColors';
import IconButton from '../buttons/IconButton';
import { Card, CardInteractivePart } from '../cards/Card';
import TimeAgo from '../dates/TimeAgo';
import Text from '../text/Text';
import { SelectCardListLoader } from './SelectCardListLoader';
import { SelectCardListEmpty } from './SelectCardListEmpty';
import { IActionItem } from '../../model/IActionItem';
import { IActivityItem } from '../activity/IActivityItem';
import ActivityList from '../activity/ActivityList';
import ActionsDropdownMenu from '../drop-down-menu/ActionsDropdownMenu';

export interface SelectCardListPreviewItem {
  id: string;
  color: ButtonColors;
  icon: React.ReactElement;
  onClick: () => void;
  borderType?: 'none' | 'dotted';
}

export interface ISelectCardListItemProps {
  id: string;
  title: string;
  updatedTime: string;
  imageUrl?: string;
  previewItems?: SelectCardListPreviewItem[];
  previewItemsAppendPlaceholders?: boolean;
  minimunPreviewSlots?: number;
  onPreviewPlaceholderClick?: () => void;
  totalItemCount?: number;
  totalItemText?: string;
  activityItems?: IActivityItem[];
  actionsResolver?: (isOpen: boolean) => { items: IActionItem[]; isPending: boolean };
  onClick: () => void;
}

export function SelectCardListItem({
  id,
  title,
  updatedTime,
  imageUrl,
  minimunPreviewSlots = 4,
  previewItemsAppendPlaceholders = false,
  previewItems,
  totalItemCount,
  totalItemText = 'objects',
  activityItems,
  actionsResolver,
  onClick,
  onPreviewPlaceholderClick,
}: ISelectCardListItemProps) {
  const appendPlaceholderItems = (
    existingItems: SelectCardListPreviewItem[],
  ): SelectCardListPreviewItem[] => {
    const actualExistingItems = existingItems.slice(0, minimunPreviewSlots);
    const remainingSlots = minimunPreviewSlots - actualExistingItems.length;
    if (remainingSlots > 0) {
      for (let i = 0; i < remainingSlots; i++) {
        actualExistingItems.push({
          id: `PlaceholderPreviewItem${i.toString()}`,
          color: ButtonColors.Gray,
          icon: <PlusIcon className="w-6 h-6 opacity-75" />,
          onClick: onPreviewPlaceholderClick || (() => undefined),
          borderType: 'dotted',
        });
      }
    }

    return actualExistingItems;
  };

  let actualPreviewItems = previewItems;
  if (actualPreviewItems && previewItemsAppendPlaceholders) {
    actualPreviewItems = appendPlaceholderItems(actualPreviewItems);
  }
  return (
    <Card
      as="div"
      key={id}
      isInteractive
      isChildrenInteractive
      onClick={onClick}
      width="small"
      height="fit"
    >
      <div className="flex flex-col h-full">
        {actualPreviewItems && actualPreviewItems.length > 0 && (
          <CardInteractivePart className="grid grid-cols-2 grid-rows-2 gap-2">
            {actualPreviewItems.map((item) => {
              const classes = clsx(
                'flex justify-center align-middle h-16',
                'cursor-pointer hover:outline-cyan-500 hover:outline hover:outline-offset-[-2px]',
                item.borderType && item.borderType !== 'none'
                  ? 'border-dotted border-2 rounded-md'
                  : 'rounded-md',
              );
              return (
                <div key={item.id} className={classes}>
                  <IconButton
                    color={item.color}
                    icon={item.icon}
                    grow
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      item.onClick();
                    }}
                  />
                </div>
              );
            })}
          </CardInteractivePart>
        )}
        {imageUrl && (
          <div
            style={{ backgroundImage: `url(${imageUrl})` }}
            className="h-32 bg-cover bg-no-repeat"
          />
        )}
        <div className="mt-auto py-2">
          <div className="flex flex-1 items-center">
            <div className="flex flex-1 truncate">
              <Text size="normal" weight="medium" whitespace="normal" breakMode="normal" truncate>
                {title}
              </Text>
            </div>

            {actionsResolver && (
              <CardInteractivePart>
                <ActionsDropdownMenu
                  resolver={actionsResolver}
                  menuTriggerIconType="vertical"
                  menuTriggerIconSize="small"
                />
              </CardInteractivePart>
            )}
          </div>
          <div className="flex justify-between items-center">
            <Text size="xSmall">
              {totalItemCount !== null && totalItemCount !== undefined && (
                <>
                  {totalItemCount} {totalItemText}
                </>
              )}
            </Text>
            <Text size="xSmall" opacity="75" truncate>
              <TimeAgo date={updatedTime} /> ago
            </Text>
          </div>
        </div>
        {activityItems && activityItems.length > 0 && (
          <ActivityList items={activityItems} justify />
        )}
      </div>
    </Card>
  );
}

type Orientation = 'horizontal' | 'vertical';

interface ISelectCardListProps {
  orientation?: Orientation;
  isLoading?: boolean;
  wrap?: boolean;
  isEmpty?: boolean;
  emptyText?: string;
  children: React.ReactNode;
}

export default function SelectCardList({
  orientation = 'horizontal',
  isLoading = false,
  wrap = true,
  isEmpty = false,
  emptyText,
  children,
}: ISelectCardListProps) {
  const classes = clsx(
    'flex w-full justify-start',
    wrap ? 'flex-wrap' : '',
    orientation === 'horizontal'
      ? 'flex-row overflow-x-scroll gap-x-4 horizontal-scroll'
      : 'flex-col overflow-y-scroll gap-y-4 overflow-hidden',
  );

  return (
    <section className="flex justify-left items-start  overflow-x-hidden">
      <nav className={classes}>
        {isLoading && <SelectCardListLoader />}
        {!isLoading && !isEmpty && children}
        {!isLoading && isEmpty && <SelectCardListEmpty text={emptyText} />}
      </nav>
    </section>
  );
}
