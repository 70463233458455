import { Bars3Icon, Cog6ToothIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router';
import { BaseLayout } from '../../shared/components/layout/pages/BaseLayout';
import ProjectsSearch from '../Projects/ProjectsSearch';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../shared/components/drop-down-menu/DropdownMenu';
import { renderDropdownMenuItems } from '../../shared/components/drop-down-menu/utils';
import { IServiceHeaderAction } from '../../shared/components/layout/building-blocks/ServiceHeader';
import { useOrganizationQuery } from './queries/organizationQueries';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import useCompanyBreadcrumbNavigation from './hooks/useCompanyBreadcrumbNavigation';
import DropdownNavigator from '../../shared/components/navigation/DropdownNavigator';
import useCompanyNavigation from './hooks/useCompanyNavigation';
import useCompanyActions from './hooks/useCompanyActions';

export default function Company() {
  const { tenantId } = useParams();

  const navigate = useNavigate();

  const { breadcrumbNavigatorItems } = useCompanyBreadcrumbNavigation();

  const { items: companyNavItems } = useCompanyNavigation();

  const setActiveViewLayout = useGlobalStore((state) => state.setActiveViewLayout);
  const activeViewLayout = useGlobalStore(
    (state) => state.getViewSettings('project').activeViewLayout,
  );

  const organizationQuery = useOrganizationQuery(tenantId as string);
  const organization = organizationQuery.data;

  const handleOnNavigateBack = () => {
    navigate('/companies');
  };

  const { items: tabMenuItems } = useCompanyActions(handleOnNavigateBack);

  const headerActions: IServiceHeaderAction[] = [
    {
      id: 'cardView',
      onClick: () => {
        setActiveViewLayout('project', 'card');
        return Promise.resolve();
      },
      icon: Squares2X2Icon,
      isActive: activeViewLayout === 'card',
    },
    {
      id: 'listView',
      onClick: () => {
        setActiveViewLayout('project', 'list');
        return Promise.resolve();
      },
      icon: Bars3Icon,
      isActive: activeViewLayout === 'list',
    },
    {
      id: 'editOrganization',
      onClick: () => Promise.resolve(),
      icon:
        tabMenuItems.length > 0 ? (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Cog6ToothIcon className="w-6 h-6 text-gray-dark stroke-2" />
            </DropdownMenuTrigger>
            <DropdownMenuContent>{renderDropdownMenuItems(tabMenuItems)}</DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <></>
        ),
    },
  ];

  if (!organization) {
    return <></>;
  }

  return (
    <>
      <BaseLayout
        title={organizationQuery.data?.name}
        headerActions={headerActions}
        breadcrumbsNavigatorItems={breadcrumbNavigatorItems}
        contentAfterHeader={<DropdownNavigator items={companyNavItems} />}
      >
        <ProjectsSearch activeViewLayout={activeViewLayout} />
      </BaseLayout>
    </>
  );
}
