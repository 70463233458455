import { useState } from 'react';
import { addDays, addHours, format, nextMonday, set } from 'date-fns';
import { BellIcon, CalendarIcon, TrashIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import { IActivityDto } from '../../shared/model/IActitityDto';
import IconButton from '../../shared/components/buttons/IconButton';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import ActivityAssignToModal from './ActivityAssignToModal';
import {
  useDeleteActivityMutation,
  useExpirationOnActivityMutation,
  useReminderOnActivityMutation,
  useSetAssignedToOnActivityMutation,
} from './queries/activitiesQueries';
import { CardMenuBar } from '../../shared/components/cards/CardMenuBar';
import { Filler } from '../../shared/components/layout/Filler';
import ConfirmDialogLegacy from '../../shared/components/alert-dialog/ConfirmDialogLegacy';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuDivider,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuPopoverItem,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '../../shared/components/drop-down-menu/DropdownMenu';
import { DatePicker } from '../../shared/components/dates/DatePicker';
import { ResourceType } from '../../shared/model/ResourceType';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

interface IActivityActionMenuBarProps {
  resourceIds: ResourceIds;
  activity: IActivityDto;
  showAssignedTo: boolean;
  entityType: ResourceType;
}

const resetTimePartOfDate = (date: Date) =>
  set(date, { hours: 9, seconds: 0, minutes: 0, milliseconds: 0 });

function ExpiresMenu({
  resourceIds,
  activity,
  entityType,
}: {
  activity: IActivityDto;
  resourceIds: ResourceIds;
  entityType: ResourceType;
}) {
  const [open, setOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const expirationMutation = useExpirationOnActivityMutation(resourceIds, entityType);

  const handleSetExpire = (expireAtTime: string) => {
    expirationMutation.mutate({ activityId: activity.id, expireAtTime });
  };

  const nowBaseDate = new Date();
  const todayBaseDate = resetTimePartOfDate(nowBaseDate);

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          color={ButtonColors.Gray}
          icon={<CalendarIcon className="w-5 h-5" />}
          label="Expires"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => handleSetExpire(todayBaseDate.toISOString())}>
          <DropdownMenuIcon icon={CalendarIcon} />
          Today
          <DropdownMenuShortcut>{format(todayBaseDate, 'iii')}</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleSetExpire(addDays(todayBaseDate, 1).toISOString())}>
          <DropdownMenuIcon icon={CalendarIcon} />
          Tomorrow
          <DropdownMenuShortcut>{format(addDays(todayBaseDate, 1), 'iii')}</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleSetExpire(nextMonday(todayBaseDate).toISOString())}>
          <DropdownMenuIcon icon={CalendarIcon} />
          Next week
          <DropdownMenuShortcut>{format(nextMonday(todayBaseDate), 'iii')}</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuDivider />
        <DropdownMenuPopoverItem
          open={datePickerOpen}
          onOpenChange={setDatePickerOpen}
          popoverContent={
            <DatePicker
              initialDate={activity.expireTime}
              onSelect={(date) => {
                handleSetExpire(date.toISOString());
                setDatePickerOpen(false);
                setOpen(false);
              }}
            />
          }
        >
          <DropdownMenuIcon icon={CalendarIcon} />
          Pick a date
        </DropdownMenuPopoverItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function ReminderMenu({
  activity,
  resourceIds,
  entityType,
}: {
  activity: IActivityDto;
  resourceIds: ResourceIds;
  entityType: ResourceType;
}) {
  const [open, setOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const reminderMutation = useReminderOnActivityMutation(resourceIds, entityType);

  const nowBaseDate = new Date();
  const todayBaseDate = resetTimePartOfDate(nowBaseDate);

  const handleSetReminder = (remindAtTime: string) => {
    reminderMutation.mutate({ activityId: activity.id, remindAtTime });
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <IconButton
          color={ButtonColors.Gray}
          icon={<BellIcon className="w-5 h-5" />}
          label="Remind"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => handleSetReminder(addHours(nowBaseDate, 3).toISOString())}>
          <DropdownMenuIcon icon={BellIcon} />
          Later
          <DropdownMenuShortcut>{format(addHours(nowBaseDate, 3), 'HH:mm')}</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => handleSetReminder(addDays(todayBaseDate, 1).toISOString())}
        >
          <DropdownMenuIcon icon={BellIcon} />
          Tomorrow
          <DropdownMenuShortcut>{format(addDays(todayBaseDate, 1), 'HH:mm')}</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => handleSetReminder(nextMonday(todayBaseDate).toISOString())}
        >
          <DropdownMenuIcon icon={BellIcon} />
          Next week
          <DropdownMenuShortcut>{format(nextMonday(todayBaseDate), 'HH:mm')}</DropdownMenuShortcut>
        </DropdownMenuItem>
        <DropdownMenuDivider />
        <DropdownMenuPopoverItem
          open={datePickerOpen}
          onOpenChange={setDatePickerOpen}
          popoverContent={
            <DatePicker
              initialDate={activity.expireTime}
              hasTime
              onSelect={(date) => {
                handleSetReminder(date.toISOString());
                setDatePickerOpen(false);
                setOpen(false);
              }}
            />
          }
        >
          <DropdownMenuIcon icon={BellIcon} />
          Pick a date & time
        </DropdownMenuPopoverItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function ActivityActionMenuBar({
  resourceIds,
  activity,
  showAssignedTo,
  entityType,
}: IActivityActionMenuBarProps) {
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);

  const deleteMutation = useDeleteActivityMutation(resourceIds, entityType);
  const assigneeMutation = useSetAssignedToOnActivityMutation(resourceIds, entityType);

  const handleDelete = () => {
    deleteMutation.mutate({ activityId: activity.id });
  };

  const handleSetAssignedTo = (assignedTo: { id: string }) => {
    assigneeMutation.mutate({ activityId: activity.id, userId: assignedTo.id });
  };

  return (
    <>
      <CardMenuBar>
        <ExpiresMenu resourceIds={resourceIds} activity={activity} entityType={entityType} />
        <ReminderMenu resourceIds={resourceIds} activity={activity} entityType={entityType} />

        {showAssignedTo && (
          <>
            <IconButton
              color={ButtonColors.Gray}
              icon={<UserPlusIcon className="w-5 h-5" />}
              onClick={() => {
                setIsAssigneeModalOpen(true);
              }}
              label="Assign"
            />
            <ActivityAssignToModal
              resourceIds={resourceIds}
              resourceType={entityType}
              activityId={activity.id}
              open={isAssigneeModalOpen}
              onOpenChange={setIsAssigneeModalOpen}
              isPending={assigneeMutation.isPending}
              onAssign={(result) => {
                handleSetAssignedTo({ id: result.id });
              }}
            />
          </>
        )}
        <Filler />
        <ConfirmDialogLegacy
          title="Delete task"
          description="Are you sure you want to delete this task?"
          actionText="Delete"
          onAction={handleDelete}
          asChild
        >
          <IconButton
            color={ButtonColors.Red}
            icon={<TrashIcon className="w-5 h-5" />}
            label="Delete"
          />
        </ConfirmDialogLegacy>
      </CardMenuBar>
    </>
  );
}
