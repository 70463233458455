import { ChangeEventHandler, MouseEventHandler, useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFilter,
  ColumnFiltersState,
  FilterFn,
  RowSelectionState,
} from '@tanstack/react-table';
import MemberRowCell from '../../shared/components/table/cells/MembersRowCell';
import DataTable, { createDataTableColumnHelper } from '../../shared/components/table/DataTable';
import SearchBar from '../../shared/components/search/SearchBar';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import Button from '../../shared/components/buttons/Button';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import ProfileRowCell from '../../shared/components/table/cells/ProfileRowCell';
import ActionRowCell from '../../shared/components/table/cells/ActionRowCell';
import StandardRowCell from '../../shared/components/table/cells/StandardRowCell';
import { InfiniteApiQueryScroll } from '../../shared/components/scrolling/InfiniteApiQueryScroll';
import { BoardTypeToResourceType, IBoardDto } from './models/IBoardDto';
import { useBoardsInfiniteQuery } from './queries/boardQueries';
import useBoardActions from './hooks/useBoardActions';
import { BoardActionButtons } from './BoardActionButtons';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { IActivityItem } from '../../shared/components/activity/IActivityItem';
import { createActivityColumnDefinition } from '../Tracking/trackingHelpers';

const DefaultColumnFilters = [] as ColumnFilter[];

const customFilterFunction: FilterFn<IBoardDto> = (row, columnId, filterValue) => {
  const value = row.getValue(columnId) as string;
  return value.toLowerCase().includes(filterValue.toLowerCase());
};

export interface IBoardsDataTableProp {
  projectId: string;
}

export default function BoardsDataTable({ projectId }: IBoardsDataTableProp) {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(DefaultColumnFilters);
  const contactManifest = useEntityManifest(ResourceType.Contact);
  const [searchTerm, setSearchTerm] = useState('');
  const myUserId = useMyUserId();

  // const actualSelectedRows = Object.keys(selectedRows)
  //   .filter((item) => selectedRows[item])
  //   .map((item) => item);
  const handleSearchChanged: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearFilter: MouseEventHandler<HTMLButtonElement> = () => {
    setColumnFilters(DefaultColumnFilters);
  };

  const columns = useMemo(() => {
    const columnHelper = createDataTableColumnHelper<IBoardDto>();
    const activityHeaderItems = [
      { type: 'Contact', label: 'New contacts' },
      { type: 'Conversation', label: 'New conversations' },
      { type: 'Task', label: 'New tasks' },
      { type: 'Mention', label: 'New mentions' },
    ] as IActivityItem[];
    return [
      // columnHelper.multiSelect(),
      columnHelper.accessor('name', {
        id: 'name',
        header: 'Name',
        filterFn: customFilterFunction,
        minSize: 250,
        cell: ({ row, getValue }) => (
          <ProfileRowCell
            photoUrl={
              row.original.photoUrl ||
              useEntityManifest(BoardTypeToResourceType[row.original.type]).icon
            }
            name={getValue()}
          />
        ),
      }),
      columnHelper.accessor('description', {
        id: 'description',
        header: 'Description',
        filterFn: customFilterFunction,
        cell: ({ getValue }) => <StandardRowCell text={getValue()} />,
      }),
      columnHelper.accessor('memberCount', {
        meta: {
          size: '175px',
        },
        header: () => 'Members',
        cell: ({ getValue }) => (
          <MemberRowCell
            members={Array(getValue())
              .fill(1)
              .map((_, i) => ({ id: i.toString() }))}
            fallbackSrc={contactManifest.icon}
          />
        ),
      }),
      columnHelper.accessor('visits', {
        ...createActivityColumnDefinition<IBoardDto>({
          id: 'visits',
          activityItems: activityHeaderItems,
          myUserId: myUserId || '',
        }),
      }),
      columnHelper.actions(({ row }) => (
        <ActionRowCell
          resolver={(isOpen) => {
            const payload = isOpen ? { projectId, boardId: row.original.id } : {};
            return useBoardActions(payload.projectId, payload.boardId);
          }}
        />
      )),
    ] as ColumnDef<IBoardDto>[];
  }, []);

  const boardsQuery = useBoardsInfiniteQuery({ projectId });

  if (boardsQuery.error) return <div>Error: {boardsQuery.error.message}</div>;

  const hasAppliedFilters = columnFilters !== DefaultColumnFilters;
  return (
    <div className="flex flex-col overflow-hidden">
      <div className="flex items-center gap-3">
        <div className="grow xl:max-w-96">
          <SearchBar
            placeholder="Search to find boards"
            onSearchChange={handleSearchChanged}
            searchTerm={searchTerm}
          />
        </div>
        <BoardActionButtons projectId={projectId} />
        <div className="flex flex-1 justify-end">
          <Button
            color={ButtonColors.White}
            disabled={!hasAppliedFilters}
            onClick={handleClearFilter}
            text="X Clear Filter"
          />
        </div>
      </div>
      <div className="flex flex-col justify-items-center overflow-hidden">
        <InfiniteApiQueryScroll query={boardsQuery}>
          {({ data }) => (
            <DataTable
              isLoading={boardsQuery.isPending}
              getRowId={(row) => row.id}
              columns={columns}
              data={data}
              columnFilters={columnFilters}
              onColumnFiltersChange={setColumnFilters}
              globalFilter={searchTerm}
              rowSelection={selectedRows}
              onRowSelectionChange={setSelectedRows}
            />
          )}
        </InfiniteApiQueryScroll>
      </div>
    </div>
  );
}
