import { useState } from 'react';
import Button from '../../../../shared/components/buttons/Button';
import { ButtonColors } from '../../../../shared/constants/ButtonColors';
import { IContactDto } from '../../../Contacts/models/IContactDto';
import { IResourceProps } from './PipelineCreateItemPopover';
import { SelectContact } from '../../../Selectors/SelectContact';
import Header2 from '../../../../shared/components/headers/Header2';
import FlexContainer from '../../../../shared/components/structure/FlexContainer';
import { useCreatePipelineItemMutation } from '../../queries/itemQueries';
import { useShareResourceMutation } from '../../queries/pipelineSharedResourcesQueries';
import { usePipelineStoreContext } from '../../context/pipeline-context';
import { ResourceType } from '../../../../shared/model/ResourceType';

export default function PipelineAddContactItem({ stageId, onSubmitComplete }: IResourceProps) {
  const [selectedContact, setSelectedContact] = useState<IContactDto | undefined>(undefined);

  const createPipelineItemMutation = useCreatePipelineItemMutation();
  const shareResourceMutation = useShareResourceMutation();

  const { activePipelineSettings } = usePipelineStoreContext();

  const onSubmit = () => {
    if (!selectedContact) return;

    let sharedResourceId = '';

    shareResourceMutation
      .mutateAsync({
        pipelineId: activePipelineSettings.pipelineId,
        request: {
          resourceId: selectedContact.id,
          type: ResourceType.Contact,
          isLiveUpdated: true,
        },
      })
      .then((result) => {
        sharedResourceId = result.id;

        createPipelineItemMutation.mutateAsync({
          pipelineId: activePipelineSettings.pipelineId,
          stageId,
          item: {
            name: selectedContact.displayName,
            value: 0,
            description: '',
            sharedResourceId,
          },
        });

        onSubmitComplete(false);
        setSelectedContact(undefined);
      });
  };

  return (
    <FlexContainer direction="column" gap="small">
      <Header2>Select Contact</Header2>
      <SelectContact onSelect={setSelectedContact} />
      <FlexContainer justify="end">
        <Button
          onClick={onSubmit}
          color={ButtonColors.Blue}
          text="Add"
          disabled={!selectedContact}
        />
      </FlexContainer>
    </FlexContainer>
  );
}
