import { useNavigate } from 'react-router';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import Howto from '../../shared/components/message-popups/HowTo';
import ProjectMembers from './ProjectMembers';
import { useProjectPrivilegesQuery } from './queries/projectPriviligesQueries';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import useProjectActions from './hooks/useProjectActions';
import { IProjectDto } from './models/IProjectDto';
import { useMessagesQuery, UseMessagesQueryResult } from '../Messaging/queries/messageQueries';
import Messaging from '../Messaging/Messaging';
import { useResourceConversationQuery } from '../Messaging/queries/conversationQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import Attachments from '../../shared/components/attachments/Attachments';
import { useGlobalStore } from '../../shared/store/useGlobalStore';
import Activities from '../Activities/Activities';

interface IProjectProps {
  project: IProjectDto;
}

export default function Project({ project }: IProjectProps) {
  const activeEditProjectTab = useGlobalStore((state) => state.activeEditProjectTab);
  const setActiveEditProjectTab = useGlobalStore((state) => state.setActiveEditProjectTab);
  const privilegesQuery = useProjectPrivilegesQuery(project.id);
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate(`/companies/${project?.tenantId}`);
  };

  const { items: menuItems } = useProjectActions(project, handleNavigateBack, handleNavigateBack);
  const conversationQuery = useResourceConversationQuery(project.id, ResourceType.Project);
  const conversation = conversationQuery.data;
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);

  if (!privilegesQuery.isSuccess) {
    return <></>;
  }
  const privileges = privilegesQuery.data;

  return (
    <>
      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="tab_info"
            activeId={activeEditProjectTab}
            title="Info"
            setActiveTab={() => setActiveEditProjectTab('tab_info')}
          />
          {privileges.canReadMembers && (
            <TabNavItem
              id="tab_chat"
              activeId={activeEditProjectTab}
              title="Chat"
              setActiveTab={() => setActiveEditProjectTab('tab_chat')}
            />
          )}
          {privileges.canReadActivities && (
            <TabNavItem
              id="tab_activities"
              activeId={activeEditProjectTab}
              title="Tasks"
              setActiveTab={() => setActiveEditProjectTab('tab_activities')}
            />
          )}
          {privileges.canReadMembers && (
            <TabNavItem
              id="tab_attachments"
              activeId={activeEditProjectTab}
              title="Attachments"
              setActiveTab={() => setActiveEditProjectTab('tab_attachments')}
            />
          )}
          {privileges.canReadMembers && (
            <TabNavItem
              id="tab_members"
              activeId={activeEditProjectTab}
              title="Members"
              setActiveTab={() => setActiveEditProjectTab('tab_members')}
            />
          )}
          <TabDropdownItem isActive={false} menuItems={menuItems} />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="tab_info" activeTabId={activeEditProjectTab}>
            <div className="flex-1 min-h-0 flex flex-col sm:px-0">
              <div className="flex-1 max-w-4xl mt-4">
                <HeaderSubHeader
                  size="small"
                  text="Description"
                  subText={project?.description || ''}
                />
              </div>
              <Howto title="Info" description="Change the name and description of your project." />
            </div>
          </TabContent>
          <TabContent id="tab_chat" activeTabId={activeEditProjectTab}>
            {conversation && (
              <Messaging
                messages={messagesQuery as UseMessagesQueryResult}
                conversation={conversation}
              />
            )}
          </TabContent>
          <TabContent id="tab_attachments" activeTabId={activeEditProjectTab}>
            <Attachments conversationId={conversation?.id as string} />
          </TabContent>
          <TabContent id="tab_members" activeTabId={activeEditProjectTab}>
            <ProjectMembers projectId={project.id} />
          </TabContent>
          <TabContent id="tab_activities" activeTabId={activeEditProjectTab}>
            <Activities resourceIds={[project.id]} type={ResourceType.Project} />
          </TabContent>
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
