import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { IActionItem } from '../../model/IActionItem';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../drop-down-menu/DropdownMenu';
import { renderDropdownMenuItems } from '../drop-down-menu/utils';
import BorderActiveHorizontal from '../indicators/BorderActiveHorizontal';

interface IProps {
  isActive: boolean;
  triggerElement?: JSX.Element;
  menuItems: IActionItem[];
}

export default function TabDropdownItem({
  isActive,
  menuItems,
  triggerElement = <EllipsisHorizontalIcon className="w-7 h-7" />,
}: IProps) {
  return (
    <div className="ml-auto">
      <DropdownMenu>
        <DropdownMenuTrigger>
          {triggerElement}
        </DropdownMenuTrigger>
        <DropdownMenuContent>{renderDropdownMenuItems(menuItems)}</DropdownMenuContent>
      </DropdownMenu>
      {isActive && <BorderActiveHorizontal thickness="thick" bottomOffset={-3} />}
    </div>
  );
}
