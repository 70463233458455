/* eslint-disable react/jsx-props-no-multi-spaces */
import { BellIcon } from '@heroicons/react/24/outline';
import { BellIcon as BellIconSolid } from '@heroicons/react/24/solid';
import { useContext, useMemo, useState } from 'react';
import {
  useFloating,
  FloatingFocusManager,
  useInteractions,
  useClick,
  useDismiss,
  useRole,
} from '@floating-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { ToastType } from '../../shared/components/toasts/constants/ToastTypes';
import { AppContext } from '../../shared/context/context';
import { Action, INotificationDto } from '../../shared/model/INotificationDto';
import { createErrorToast, createToast } from '../../shared/services/toastService';
import {
  useNotificationsQuery,
  useNotificationSubscription,
  useReadNotificationMutation,
} from './notificationQueries';
import NotificationsTray from './NotificationsTray';
import Text from '../../shared/components/text/Text';
import BorderActiveHorizontal from '../../shared/components/indicators/BorderActiveHorizontal';
import { getInfiniteQueryDataLength } from '../../shared/utils/queryClientUtils';

export default function Notifications() {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const notificationsQuery = useNotificationsQuery();

  useNotificationSubscription();
  useNotificationSubscription({ notificationArea: 'Contact' });
  useNotificationSubscription({ notificationArea: 'Network' });
  useNotificationSubscription({ notificationArea: 'Board' });
  useNotificationSubscription({ notificationArea: 'Project' });

  const numberOfUnreadNotifications = useMemo(
    () => getInfiniteQueryDataLength(notificationsQuery.data, (n) => !n.isRead),
    [notificationsQuery.data],
  );

  const readNotification = useReadNotificationMutation();
  const handleClearNotifications = async () => {
    notificationsQuery.data?.pages
      .flat()
      .filter((n) => !n.isRead)
      .forEach((n) => readNotification.mutate({ id: n.id }));
  };

  const handleToggleNotificationsTray = (_isOpen: boolean) => {
    if (!_isOpen) handleClearNotifications();
    setIsOpen(_isOpen);
  };

  const handleExecuteAction = (
    promise: Promise<unknown>,
    notification: INotificationDto,
    action: Action,
  ) => {
    promise
      .then(() => {
        if (action.type === 'Accept') {
          dispatch(createToast('Success', ToastType.Success, notification.acceptMessage, true));
        }
        notificationsQuery.setIsResponded(notification.id);
      })
      .catch((error) => {
        dispatch(createErrorToast(error));
      })
      .finally(() => {
        if (action.invalidateQueryKeys.length === 0) return;

        setTimeout(() => {
          action.invalidateQueryKeys.forEach((queryKey) =>
            queryClient.invalidateQueries({ queryKey }),
          );
        }, 1000);
      });
  };

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: handleToggleNotificationsTray,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useClick(context),
    useDismiss(context),
    useRole(context),
  ]);

  return (
    <>
      <button
        ref={refs.setReference}
        type="button"
        {...getReferenceProps({ className: 'relative' })}
      >
        <span className="sr-only">View notifications</span>
        {isOpen ? (
          <BellIconSolid className="h-6 w-6 text-cyan" aria-hidden="true" />
        ) : (
          <BellIcon className="h-6 w-6 text-gray-dark hover:text-cyan-dark" aria-hidden="true" />
        )}

        {numberOfUnreadNotifications > 0 && (
          <div className="absolute top-[2px] right-[-3px] inline-flex items-center justify-center w-5 h-5 transform translate-x-1/2 -translate-y-1/2 bg-red rounded-full">
            <Text as="span" size="xSmall" weight="bold" color="white" leading="none">
              {numberOfUnreadNotifications}
            </Text>
          </div>
        )}
        {isOpen && <BorderActiveHorizontal thickness="thin" bottomOffset={-12} />}
      </button>

      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div
            className="z-40 absolute flex flex-col sm:h-[650px] w-full sm:w-96 right-0 sm:right-10 top-16 sm:top-12 bottom-0 sm:bottom-auto mt-0 bg-white sm:mr-2 rounded-none sm:rounded-md sm:ring-1 sm:ring-black sm:ring-opacity-5 shadow-lg"
            {...getFloatingProps()}
          >
            <NotificationsTray
              closeCallback={() => handleToggleNotificationsTray(false)}
              onExecuteAction={handleExecuteAction}
            />
          </div>
        </FloatingFocusManager>
      )}
    </>
  );
}
