import ConfirmDialogLegacy from '../../shared/components/alert-dialog/ConfirmDialogLegacy';
import { useMyUserId } from '../../shared/auth/accountHooks';
import { useNetworkStoreContext } from './context/network-context';
import { useDeleteNetworkMutation } from './queries/networkQueries';
import { useRemoveMemberMutation } from '../Members/queries/MemberQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import NetworkCreateEditModal from './NetworkCreateEditModal';
import InviteMemberModal from '../Members/InviteMemberModal';
import { INetworkDto } from '../../shared/model/INetwork';

interface INetworkInfoModalsProps {
  network: INetworkDto;
  onDeleteNetwork?: () => void;
  onLeaveNetwork?: () => void;
}

export default function NetworkInfoModals({
  network,
  onDeleteNetwork,
  onLeaveNetwork,
}: INetworkInfoModalsProps) {
  const myUserId = useMyUserId();
  const networkId = network?.id;
  const deleteNetworkMutation = useDeleteNetworkMutation();
  const removeNetworkMemberMutation = useRemoveMemberMutation(ResourceType.Network);

  const {
    isConfirmDeleteNetworkOpen,
    setIsConfirmDeleteNetworkOpen,
    isConfirmLeaveNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
    isEditNetworkModalOpen,
    setIsEditNetworkModalOpen,
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
  } = useNetworkStoreContext();

  const handleContinueLeaveNetwork = () => {
    setIsConfirmLeaveNetworkOpen(false);
    if (!myUserId) return;
    removeNetworkMemberMutation.mutate(
      { member: { name: '', resourceId: networkId, userId: myUserId } },
      {
        onSuccess: () => {
          setIsConfirmLeaveNetworkOpen(false);
          if (onLeaveNetwork) onLeaveNetwork();
        },
      },
    );
  };

  const handleContinueDeleteNetwork = () => {
    setIsConfirmDeleteNetworkOpen(false);
    deleteNetworkMutation.mutate({ networkId, networkName: network.name as string });
    if (onDeleteNetwork) onDeleteNetwork();
  };

  if (!network) {
    return <></>;
  }
  return (
    <>
      <InviteMemberModal
        open={isInviteMemberModalOpen}
        onOpenChange={setInviteMemberModalOpen}
        type={ResourceType.Network}
        resourceIds={[networkId]}
      />
      <NetworkCreateEditModal
        open={isEditNetworkModalOpen}
        onOpenChange={setIsEditNetworkModalOpen}
        network={network}
      />
      <ConfirmDialogLegacy
        open={isConfirmDeleteNetworkOpen}
        onOpenChange={setIsConfirmDeleteNetworkOpen}
        title="Delete Network"
        description="Are you sure you want to delete this network?"
        actionText="Delete"
        onAction={handleContinueDeleteNetwork}
      />
      <ConfirmDialogLegacy
        open={isConfirmLeaveNetworkOpen}
        onOpenChange={setIsConfirmLeaveNetworkOpen}
        title="Leave Network"
        description="Are you sure you want to leave this network?"
        actionText="Leave"
        onAction={handleContinueLeaveNetwork}
      />
    </>
  );
}
