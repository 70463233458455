import { useSearchParams } from 'react-router-dom';
import {
  ArrowRightIcon,
  LinkIcon,
  PencilIcon,
  TrashIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import useCheckMobileScreen from '../../../shared/hooks/useCheckMobileScreen';
import { IActionItem } from '../../../shared/model/IActionItem';
import { useNetworkStoreContext } from '../context/network-context';
import { useNetworkPrivilegesQuery } from '../queries/networkPriviligesQueries';

export default function useNetworkActions(networkId?: string) {
  const networkPrivilegesQuery = useNetworkPrivilegesQuery(networkId);
  const networkPrivileges = networkPrivilegesQuery.data;
  const isMobile = useCheckMobileScreen();
  const [, setSearchParams] = useSearchParams();

  const {
    setInviteMemberModalOpen,
    setIsEditNetworkModalOpen,
    setIsConfirmDeleteNetworkOpen,
    setIsConfirmLeaveNetworkOpen,
  } = useNetworkStoreContext();

  const menuItems: IActionItem[] = [];

  if (isMobile) {
    menuItems.push({
      id: 'Attachments',
      title: 'Attachments',
      icon: LinkIcon,
      onClick: () => setSearchParams({ tab: 'tab_attachments' }),
      useDivider: true,
    });
  }

  if (networkPrivileges?.canInviteMember) {
    menuItems.push({
      id: 'Invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () => setInviteMemberModalOpen(true),
    });
  }
  if (networkPrivileges?.canEdit) {
    menuItems.push({
      id: 'Edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () => setIsEditNetworkModalOpen(true),
    });
  }
  if (networkPrivileges?.canDelete) {
    menuItems.push({
      id: 'Delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => setIsConfirmDeleteNetworkOpen(true),
    });
  } else {
    menuItems.push({
      id: 'Leave',
      title: 'Leave',
      icon: ArrowRightIcon,
      onClick: () => setIsConfirmLeaveNetworkOpen(true),
    });
  }

  return { items: menuItems, isPending: networkPrivilegesQuery.isPending };
}
