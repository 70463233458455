import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-calendar/dist/Calendar.css';
import { DraggableProvided } from 'react-beautiful-dnd';
import { ActivityStatus, IActivityDto } from '../../shared/model/IActitityDto';
import TextAreaInputAutosized from '../../shared/components/inputs/text/TextAreaInputAutosized';
import TextFieldInput from '../../shared/components/inputs/text/TextFieldInput';
import { ActivityStatusSelector } from './ActivityStatusSelector';
import { ActivityProperties } from './ActivityProperties';
import { ActivityActionMenuBar } from './ActivityActionMenuBar';
import {
  ExpandableCard,
  ExpandableCardRefType,
} from '../../shared/components/cards/ExpandableCard';
import {
  useBlockActivityMutation,
  useCompleteActivityMutation,
  useEditActivityMutation,
  usePauseActivityMutation,
  useStartActivityMutation,
} from './queries/activitiesQueries';
import Text from '../../shared/components/text/Text';
import HorizontalSpacer from '../../shared/components/spacing/HorizontalSpacer';
import { ResourceType } from '../../shared/model/ResourceType';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

enum EditMode {
  None = 'None',
  Title = 'Title',
  TextContent = 'TextContent',
  Status = 'Status',
}

interface IUseActivityProps {
  activity: IActivityDto;
  resourceIds: ResourceIds;
  entityType: ResourceType;
  draggableProvided: DraggableProvided;
}

function useActivity({ activity, resourceIds, entityType }: IUseActivityProps) {
  const editActivityMutation = useEditActivityMutation(resourceIds, entityType);
  const pauseActivityMutation = usePauseActivityMutation(resourceIds, entityType);
  const completeActivityMutation = useCompleteActivityMutation(resourceIds, entityType);
  const startActivityMutation = useStartActivityMutation(resourceIds, entityType);
  const blockActivityMutation = useBlockActivityMutation(resourceIds, entityType);

  const [editMode, setEditMode] = useState(EditMode.None);
  const {
    register,
    getValues,
    setFocus,
    formState: { errors, isDirty, isValid },
  } = useForm<IActivityDto>({
    mode: 'onChange',
    defaultValues: {
      id: activity?.id ?? '',
      title: activity?.title ?? '',
      text: activity?.text ?? '',
    },
  });

  useEffect(() => {
    if (editMode === EditMode.Title) setFocus('title');
    if (editMode === EditMode.TextContent) setFocus('text');
  }, [editMode]);

  const statusCallbackLookup: { [key in ActivityStatus]: (activity: IActivityDto) => void } = {
    Pending: (_activity) => pauseActivityMutation.mutate({ activityId: _activity.id }),
    InProgress: (_activity) => startActivityMutation.mutate({ activityId: _activity.id }),
    Completed: (_activity) => completeActivityMutation.mutate({ activityId: _activity.id }),
    Blocked: (_activity) => blockActivityMutation.mutate({ activityId: _activity.id }),
  };

  const submitTitle = () => {
    const data = getValues();
    const title = data.title?.trimEnd();
    if (title !== activity.title) {
      editActivityMutation.mutate({ activityId: activity.id, title });
    }
    setEditMode(EditMode.None);
  };

  const submitText = () => {
    const data = getValues();
    const text = data.text?.trimEnd();
    if (text !== activity.text) {
      editActivityMutation.mutate({ activityId: activity.id, text });
    }
    setEditMode(EditMode.None);
  };

  return {
    isEditingTitle: editMode === EditMode.Title,
    isEditingText: editMode === EditMode.TextContent,
    isEditingStatus: editMode === EditMode.Status,
    errors,
    register,
    editTitle: () => {
      setEditMode(EditMode.Title);
    },
    editText: () => {
      setEditMode(EditMode.TextContent);
    },
    editStatus: () => {
      setEditMode(EditMode.Status);
    },
    reset: () => {
      setEditMode(EditMode.None);
    },
    submitTitle,
    submitText,
    submitStatus: (status: ActivityStatus) => {
      statusCallbackLookup[status](activity);
      setEditMode(EditMode.None);
    },
    titleKeyPressEventHandler: (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key !== 'Enter' || !isValid || !isDirty) {
        return;
      }
      submitTitle();
    },
    textKeyPressEventHandler: (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key !== 'Enter' || !event.ctrlKey || !isValid || !isDirty) {
        return;
      }
      submitText();
    },
  };
}

interface IProps {
  activity: IActivityDto;
  entityType: ResourceType;
  resourceIds: ResourceIds;
  showAssignedTo?: boolean;
  draggableProvided: DraggableProvided;
}
export default function Activity({
  activity,
  entityType,
  resourceIds,
  draggableProvided,
  showAssignedTo = true,
}: IProps) {
  const {
    isEditingTitle,
    isEditingText,
    isEditingStatus,
    errors,
    register,
    editTitle,
    editText,
    editStatus,
    reset,
    submitTitle,
    submitText,
    submitStatus,
    titleKeyPressEventHandler,
    textKeyPressEventHandler,
  } = useActivity({ activity, resourceIds, entityType, draggableProvided });
  const cardRef = useRef<null | ExpandableCardRefType>(null);

  return (
    <ExpandableCard
      as="div"
      ref={cardRef}
      draggableProvided={draggableProvided}
      renderHead={(isOpen) => (
        <>
          <div className="flex p-1">
            <ActivityStatusSelector
              activity={activity}
              isEditing={isEditingStatus}
              onClick={(e) => {
                e.stopPropagation();
                if (isEditingStatus) reset();
                else editStatus();
              }}
              onSelect={(status, e) => {
                e.stopPropagation();
                submitStatus(status);
              }}
            />

            <div className="flex-1 mx-2 z-10 overflow-x-hidden">
              {isEditingTitle && (
                <TextFieldInput
                  name="title"
                  register={register}
                  type="text"
                  placeholder="Start typing to edit title..."
                  errors={errors}
                  errorMessage="Length of title must be between 1 och 255 long"
                  validationRules={{ minLength: 1, maxLength: 255 }}
                  onKeyUp={titleKeyPressEventHandler}
                  inputCss="px-0 py-0 text-base bg-transparent"
                  inputFocusCss="focus:ring-0 focus:border-0"
                  onInputBlur={submitTitle}
                />
              )}

              {!isEditingTitle && !isEditingStatus && (
                <button
                  type="button"
                  title={activity.title}
                  className="w-full cursor-pointer text-left z-10"
                  onClick={() => {
                    if (cardRef.current && !isOpen) cardRef.current.toggle();
                    editTitle();
                  }}
                >
                  <Text as="p" truncate>
                    {activity.title}
                  </Text>
                </button>
              )}
            </div>
          </div>
          <ActivityProperties activity={activity} showAssignedTo={showAssignedTo} />
        </>
      )}
    >
      {!isEditingText && (
        <button
          type="button"
          className="w-full cursor-pointer text-left mt-2 mb-2 px-2"
          onClick={editText}
        >
          {activity.text ? (
            <Text as="span" brightness="light" size="small" whitespace="preWrap">
              {activity.text}
            </Text>
          ) : (
            <Text as="span" variant="italic" brightness="light" size="small">
              Click here to add text...
            </Text>
          )}
        </button>
      )}

      {isEditingText && (
        <TextAreaInputAutosized
          name="text"
          register={register}
          placeholder="Start typing to add text..."
          errors={errors}
          errorMessage="Max length is 4000"
          validationRules={{ maxLength: 4000 }}
          onKeyUp={textKeyPressEventHandler}
          onInputBlur={submitText}
          inputCss="px-2 py-1 mt-1 text-light bg-transparent"
          inputFocusCss="focus:ring-0 focus:border-0"
        />
      )}
      <HorizontalSpacer distance="medium" />
      <ActivityActionMenuBar
        resourceIds={resourceIds}
        activity={activity}
        entityType={entityType}
        showAssignedTo={showAssignedTo}
      />
    </ExpandableCard>
  );
}
