import ServiceHeader, { IServiceHeaderAction } from '../building-blocks/ServiceHeader';
import BreadcrumbNavigator from '../../navigation/BreadcrumbNavigator/BreadcrumbNavigator';
import { IBreadcrumbNavigatorItem } from '../../navigation/BreadcrumbNavigator/helpers/breadcrumbNavigatorHelper';

export interface IBaseLayoutProps {
  title: string;
  breadcrumbsNavigatorItems?: IBreadcrumbNavigatorItem[];
  headerActions?: IServiceHeaderAction[];
  contentAfterHeader?: React.ReactNode;
  children: React.ReactNode;
}

function ServiceWindow({ children }: { children: React.ReactNode }) {
  return (
    <div className="flex flex-col h-full w-full overflow-hidden px-3 md:px-6 pb-3 md:py-4">
      {children}
    </div>
  );
}

function ServiceContent({ children }: { children: React.ReactNode }) {
  return <div className="flex-1 overflow-hidden mt-6">{children}</div>;
}

export function BaseLayout({
  title,
  breadcrumbsNavigatorItems,
  headerActions,
  contentAfterHeader,
  children,
}: IBaseLayoutProps) {
  return (
    <ServiceWindow>
      {breadcrumbsNavigatorItems && <BreadcrumbNavigator items={breadcrumbsNavigatorItems} />}
      <ServiceHeader text={title} actions={headerActions} contentAfterHeader={contentAfterHeader} />
      <ServiceContent>{children}</ServiceContent>
    </ServiceWindow>
  );
}
