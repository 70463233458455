import { useParams } from 'react-router';
import { useBoardQuery } from './queries/boardQueries';
import useVisitTracking from '../Tracking/hooks/useVisitTracking';
import { ResourceType } from '../../shared/model/ResourceType';
import { useVisitMutation } from '../Tracking/queries/visitQueries';
import { BaseLayout } from '../../shared/components/layout/pages/BaseLayout';
import useCompanyBreadcrumbNavigation from '../Companies/hooks/useCompanyBreadcrumbNavigation';
import PipelineSidePanel from '../Pipelines/PipelineSidePanel';
import { PipelineContextProvider } from '../Pipelines/context/pipeline-context';
import Pipeline from '../Pipelines/Pipeline';
import DropdownNavigator from '../../shared/components/navigation/DropdownNavigator';
import useBoardNavigation from './hooks/useBoardsNavigation';

export default function Board() {
  const params = useParams();
  const { projectId, boardId } = params;
  const boardQuery = useBoardQuery(projectId, boardId);
  const { data } = boardQuery;

  const { breadcrumbNavigatorItems } = useCompanyBreadcrumbNavigation();
  const { items: boardNavItems } = useBoardNavigation();

  const visitMutation = useVisitMutation();
  useVisitTracking({
    onVisit: () => {
      if (!projectId || !boardId) {
        return;
      }
      visitMutation.mutate({ resourceType: ResourceType.Board, resourceId: [projectId, boardId] });
    },
  });

  if (!data) {
    return <></>;
  }

  if (!data.resourceId) {
    return (
      <p>
        The required resources for the board has not yet been created. Please refresh and try
        again...
      </p>
    );
  }

  return (
    <PipelineContextProvider>
      <BaseLayout
        title={data.name}
        breadcrumbsNavigatorItems={breadcrumbNavigatorItems}
        contentAfterHeader={<DropdownNavigator items={boardNavItems} />}
      >
        <Pipeline pipelineId={data.resourceId} />
      </BaseLayout>
      <PipelineSidePanel
        projectId={data.projectId}
        boardId={data.id}
        pipelineId={data.resourceId}
      />
    </PipelineContextProvider>
  );
}
