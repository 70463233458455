import { cn } from '../../../lib/utils';
import { SpacerDistance, distanceClassLookup } from '../spacing/HorizontalSpacer';

export interface IHorizontalDividerProps {
  text?: string;
  distance?: SpacerDistance;
  border?: boolean;
}

export default function HorizontalDivider({
  text,
  distance,
  border = false,
}: IHorizontalDividerProps) {
  const spacer = distanceClassLookup[distance || 'medium'];

  const useBorder = border ? 'border-t border-black border-opacity-5' : '';

  if (text) {
    return (
      <div className={cn(spacer, 'relative flex items-center')}>
        <div className={cn(useBorder, 'flex-grow')} />
        <span className="flex-shrink mx-4 text-gray-400 text-xs">{text}</span>
        <div className={cn(useBorder, 'flex-grow')} />
      </div>
    );
  }

  return (
    <div className={cn(spacer, 'relative flex items-center')}>
      <div className={cn(useBorder, 'flex-grow')} />
    </div>
  );
}
