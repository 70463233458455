import { PencilIcon, TrashIcon, UserPlusIcon } from 'lucide-react';
import { useParams } from 'react-router';
import useModals from '../../../shared/components/modal/hooks/useModal';
import { IActionItem } from '../../../shared/model/IActionItem';
import { useOrganizationPrivilegesQuery } from '../queries/organizationPrivilegesQueries';
import { CompanyCreateEditModal } from '../CompanyCreateEditModal';
import InviteMember from '../../Members/MemberInvite';
import { useInviteOrganizationMemberMutation } from '../queries/organizationMemberQueries';
import {
  useDeleteOrganizationMutation,
  useOrganizationQuery,
} from '../queries/organizationQueries';
import { ResourceType } from '../../../shared/model/ResourceType';
import useConfirm from '../../../shared/components/alert-dialog/hooks/useConfirm';
import { ButtonColors } from '../../../shared/constants/ButtonColors';

export default function useCompanyActions(onDeleteCallback?: () => void) {
  const { tenantId } = useParams();
  const inviteMemberMutation = useInviteOrganizationMemberMutation();

  const organizationPrivilegesQuery = useOrganizationPrivilegesQuery(tenantId as string);
  const privileges = organizationPrivilegesQuery.data;

  const { openModal, closeModal } = useModals();
  const { openConfirm, closeConfirm } = useConfirm();

  const organizationQuery = useOrganizationQuery(tenantId as string);
  const organization = organizationQuery.data;
  const deleteMutation = useDeleteOrganizationMutation();

  const menuItems: IActionItem[] = [];

  if (privileges?.canEdit) {
    menuItems.push({
      id: 'edit',
      title: 'Edit',
      icon: PencilIcon,
      onClick: () =>
        openModal({
          title: 'Edit company',
          content: <CompanyCreateEditModal values={organization} />,
        }),
    });
  }
  if (privileges?.canInviteMember) {
    menuItems.push({
      id: 'invite',
      title: 'Invite',
      icon: UserPlusIcon,
      onClick: () =>
        openModal({
          title: 'Invite member',
          content: (
            <InviteMember
              customInviteMemberMutation={inviteMemberMutation}
              resourceIds={organization ? [organization.id] : []}
              type={ResourceType.Organization}
              onComplete={() => closeModal()}
            />
          ),
        }),
    });
  }
  if (privileges?.canDelete) {
    menuItems.push({
      id: 'delete',
      title: 'Delete',
      icon: TrashIcon,
      onClick: () => {
        openConfirm({
          title: 'Delete company',
          description: 'Are you sure you want to delete this company?',
          actionText: 'Delete',
          actionColor: ButtonColors.Red,
          onAction: () => {
            if (!organization) {
              closeConfirm();
              return;
            }

            closeConfirm();
            deleteMutation.mutate(organization);

            if (onDeleteCallback) onDeleteCallback();
          },
        });
      },
    });
  }

  return { items: menuItems };
}
