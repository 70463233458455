import { useState } from 'react';
import { IContactDto } from '../Contacts/models/IContactDto';
import useDebounce from '../../shared/hooks/useDebounce';
import { useContactsQuery } from '../Contacts/queries/contactQueries';
import FlexContainer from '../../shared/components/structure/FlexContainer';
import SearchBar from '../../shared/components/search/SearchBar';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import SelectList from '../../shared/components/lists/SelectList';

export function SelectContact({ onSelect }: { onSelect: (contact: IContactDto) => void }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItem, setSelectedItem] = useState<IContactDto | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(searchTerm);
  const contactsQuery = useContactsQuery({ searchTerm: debouncedSearchTerm });

  const handleOnSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value);
  };

  const handleSelectContact = (contact: IContactDto) => {
    setSelectedItem(contact);
    onSelect(contact);
  };

  if (!contactsQuery.isSuccess) return null;

  return (
    <FlexContainer direction="column">
      <SearchBar searchTerm={searchTerm} onSearchChange={handleOnSearchChange} />
      <ScrollBarWrapper className="max-h-40">
        <SelectList<IContactDto>
          data={contactsQuery.data ?? []}
          isLoading={contactsQuery.isLoading}
          itemIdProperty={({ id }) => id}
          itemHeadlineProperty={({ displayName }) => `${displayName}`}
          itemInformationProperty={({ company }) => company}
          itemImgSrcProperty={({ photoUrl }) => photoUrl}
          selectedItem={selectedItem}
          onSelectItem={handleSelectContact}
        />
      </ScrollBarWrapper>
    </FlexContainer>
  );
}
