import React from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

// Define the variant styles using CVA
const flexContainerStyles = cva('flex', {
  variants: {
    direction: {
      row: 'flex-row',
      column: 'flex-col',
      'row-reverse': 'flex-row-reverse',
      'column-reverse': 'flex-col-reverse',
    },
    align: {
      start: 'items-start',
      center: 'items-center',
      end: 'items-end',
      stretch: 'items-stretch',
      baseline: 'items-baseline',
    },
    justify: {
      start: 'justify-start',
      center: 'justify-center',
      end: 'justify-end',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
    },
    wrap: {
      nowrap: 'flex-nowrap',
      wrap: 'flex-wrap',
      'wrap-reverse': 'flex-wrap-reverse',
    },
    gap: {
      none: 'gap-0',
      small: 'gap-2',
      medium: 'gap-4',
      large: 'gap-6',
      xl: 'gap-8',
    },
    // Add more variants as needed
  },
  defaultVariants: {
    direction: 'row',
    align: 'stretch',
    justify: 'start',
    wrap: 'nowrap',
    gap: 'medium',
  },
});

// Define the component props, extending the variants
interface FlexContainerProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof flexContainerStyles> {}

// The FlexContainer component
function FlexContainer({
  direction,
  align,
  justify,
  wrap,
  gap,
  className,
  children,
  ...props
}: FlexContainerProps) {
  return (
    <div
      className={clsx(flexContainerStyles({ direction, align, justify, wrap, gap }), className)}
      {...props}
    >
      {children}
    </div>
  );
}

export default FlexContainer;
