import { createContext, useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { IPipelineItemDto } from '../models/IPipelineItemDto';

export type SidePanelViewType = 'EditPipeline' | 'EditItem';

export type EditPipelineTabs =
  | 'tab_info'
  | 'tab_members'
  | 'tab_stages'
  | 'tab_budget'
  | 'tab_attachments'
  | 'tab_activities'
  | 'tab_chat';

export interface ActivePipelineSettings {
  pipelineId: string;
  stageId: string;
  item: IPipelineItemDto;
}

interface Store {
  activeSidePanelView: SidePanelViewType;
  setActiveSidePanelView: (view: SidePanelViewType) => void;
  navigateToOverview: () => void;
  selectStage: (stageId: string) => void;
  selectItem: (item: IPipelineItemDto, stageId: string) => void;
  selectPipeline: (pipelineId: string) => void;
  activePipelineSettings: ActivePipelineSettings;
  isSelectPipelineVisible: boolean;
  setIsSelectPipelineVisible: (isVisible: boolean) => void;
  closeSidebar: () => void;
  activeEditPipelineTab: EditPipelineTabs;
  setActiveEditPipelineTab: (tab: EditPipelineTabs) => void;
  isInviteMemberModalOpen: boolean;
  setInviteMemberModalOpen: (isOpen: boolean) => void;
  isConfirmLeaveOpen: boolean;
  setIsConfirmLeaveOpen: (isOpen: boolean) => void;
  isConfirmDeleteOpen: boolean;
  setIsConfirmDeleteOpen: (isOpen: boolean) => void;
  isEditModalOpen: boolean;
  setIsEditModalOpen: (isOpen: boolean) => void;
  resetPipelineSettings: () => void;
}

const usePipelineStore = (): Store => {
  const [activeSidePanelView, setActiveSidePanelView] = useState<SidePanelViewType>('EditPipeline');
  const [isSelectPipelineVisible, setIsSelectPipelineVisible] = useState<boolean>(false);

  const [activeEditPipelineTab, setActiveEditPipelineTab] = useState<EditPipelineTabs>('tab_info');
  const [isInviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
  const [isConfirmLeaveOpen, setIsConfirmLeaveOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const defaultPipelineSettings = {
    stageId: '',
    pipelineId: '',
    item: {} as IPipelineItemDto,
  };

  const [activePipelineSettings, setActivePipelineSettings] =
    useState<ActivePipelineSettings>(defaultPipelineSettings);

  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const navigateToOverview = () => {
    navigate('/boards');
    setActivePipelineSettings(defaultPipelineSettings);
  };

  const selectStage = (stageId: string) => {
    setActivePipelineSettings((prevState) => ({
      ...prevState,
      stageId,
    }));

    setActiveEditPipelineTab('tab_stages');
    setActiveSidePanelView('EditPipeline');
  };

  const selectPipeline = (pipelineId: string) => {
    setActivePipelineSettings((prevState) => ({
      ...prevState,
      pipelineId,
    }));
    setActiveEditPipelineTab('tab_info');
    setSearchParams({ tab: 'tab_info' });

    setActiveSidePanelView('EditPipeline');
  };

  const selectItem = (item: IPipelineItemDto, stageId: string) => {
    setActivePipelineSettings((prevState) => ({
      ...prevState,
      stageId,
      item,
    }));

    setActiveEditPipelineTab('tab_info');
    setSearchParams({ tab: 'tab_info' });
    setActiveSidePanelView('EditItem');
  };

  const resetPipelineSettings = () => {
    setActivePipelineSettings((prevState) => ({
      ...defaultPipelineSettings,
      pipelineId: prevState.pipelineId,
    }));

    setActiveSidePanelView('EditPipeline');
  };

  const closeSidebar = () => {
    setActivePipelineSettings(defaultPipelineSettings);
  };

  return {
    activePipelineSettings,
    activeSidePanelView,
    setActiveSidePanelView,
    navigateToOverview,
    selectStage,
    selectItem,
    selectPipeline,
    isSelectPipelineVisible,
    setIsSelectPipelineVisible,
    closeSidebar,
    activeEditPipelineTab,
    setActiveEditPipelineTab,
    isInviteMemberModalOpen,
    setInviteMemberModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
    isConfirmLeaveOpen,
    setIsConfirmLeaveOpen,
    isConfirmDeleteOpen,
    setIsConfirmDeleteOpen,
    resetPipelineSettings,
  };
};

export const PipelineStoreContext = createContext<Store | null>(null);

export function PipelineContextProvider({ children }: { children: ReactNode }) {
  return (
    <PipelineStoreContext.Provider value={usePipelineStore()}>
      {children}
    </PipelineStoreContext.Provider>
  );
}

export const usePipelineStoreContext = (): Store => {
  const store = useContext(PipelineStoreContext);

  if (!store) {
    throw new Error('usePipelineStoreContext must be used within a PipelineContextProvider');
  }

  return store;
};
