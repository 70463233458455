import { useState } from 'react';
import { Bars3Icon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { useNavigate, useParams } from 'react-router';
import { IServiceHeaderAction } from '../../shared/components/layout/building-blocks/ServiceHeader';
import { useProjectQuery } from './queries/projectQueries';
import BoardsListView from './BoardsListView';
import BoardsCardView from './BoardsCardView';
import BoardCreateModal from './BoardCreateModal';
import { useProjectPrivilegesQuery } from './queries/projectPriviligesQueries';
import { ResourceType } from '../../shared/model/ResourceType';
import useVisitTracking from '../Tracking/hooks/useVisitTracking';
import { useVisitMutation } from '../Tracking/queries/visitQueries';
import { BaseLayout } from '../../shared/components/layout/pages/BaseLayout';
import ProjectSidePanel from './ProjectSidePanel';
import useCompanyBreadcrumbNavigation from '../Companies/hooks/useCompanyBreadcrumbNavigation';
import { ViewLayout } from '../../shared/store/slices/viewSlice';
import DropdownNavigator from '../../shared/components/navigation/DropdownNavigator';
import useProjectNavigation from './hooks/useProjectNavigation';

export default function ProjectsSearchBoards() {
  const [activeView, setActiveView] = useState<ViewLayout>('card');
  const params = useParams();
  const projectQuery = useProjectQuery(params.projectId);
  const projectPriviligesQuery = useProjectPrivilegesQuery(params.projectId);

  const { breadcrumbNavigatorItems } = useCompanyBreadcrumbNavigation();

  const { items: projectNavItems } = useProjectNavigation();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const navigate = useNavigate();

  const visitMutation = useVisitMutation();
  useVisitTracking({
    onVisit: () => {
      if (!params.projectId) {
        return;
      }
      visitMutation.mutate({ resourceType: ResourceType.Project, resourceId: [params.projectId] });
    },
  });

  const handleCreateBoardSuccess = (boardId: string) => {
    navigate(`projects/${params.projectId}/boards/${boardId}`);
  };

  const headerActions: IServiceHeaderAction[] = [
    {
      id: 'cardView',
      onClick: () => {
        setActiveView('card');
        return Promise.resolve();
      },
      icon: Squares2X2Icon,
      isActive: activeView === 'card',
    },
    {
      id: 'listView',
      onClick: () => {
        setActiveView('list');
        return Promise.resolve();
      },
      icon: Bars3Icon,
      isActive: activeView === 'list',
    },
  ];

  return (
    <>
      <BaseLayout
        title={projectQuery.data?.displayName || ''}
        headerActions={headerActions}
        breadcrumbsNavigatorItems={breadcrumbNavigatorItems}
        contentAfterHeader={<DropdownNavigator items={projectNavItems} />}
      >
        {projectQuery.data &&
          params.projectId &&
          (activeView === 'card' ? (
            <BoardsCardView projectId={params.projectId} />
          ) : (
            <BoardsListView projectId={params.projectId} />
          ))}
      </BaseLayout>

      <ProjectSidePanel />

      {projectQuery.data &&
        projectPriviligesQuery.data &&
        projectPriviligesQuery.data.canCreateBoard && (
          <BoardCreateModal
            projectId={projectQuery.data?.id}
            onOpenChange={setIsCreateModalOpen}
            open={isCreateModalOpen}
            onSuccess={handleCreateBoardSuccess}
          />
        )}
    </>
  );
}
