import { Slash } from 'lucide-react';
import { Fragment } from 'react';

import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbSeparator,
  BreadcrumbItem,
} from '../../breadcrumb/Breadcrumb';
import { IBreadcrumbNavigatorItem } from './helpers/breadcrumbNavigatorHelper';

import Text from '../../text/Text';
import DropdownNavigator from '../DropdownNavigator';

interface BreadcrumbNavigatorItemProps {
  item: IBreadcrumbNavigatorItem;
}

function BreadcrumbNavigatorItem({ item }: BreadcrumbNavigatorItemProps) {
  return (
    <>
      {item.siblings && item.siblings.length > 0 ? (
        <>
          <Link to={item.route + item.id}>
            <Text color="cyan" weight="semibold">
              {item.name}
            </Text>
          </Link>

          <DropdownNavigator
            triggerClassName="w-5 h-5 mt-1"
            items={item.siblings.map((s) => ({
              id: s.id,
              name: s.name,
              url: item.route + s.id,
            }))}
          />
        </>
      ) : (
        <>
          {item.isDisabled ? (
            <Text color="normal" brightness="light">
              {item.name}
            </Text>
          ) : (
            <Link to={item.route + item.id}>
              <Text color="cyan" weight="semibold">
                {item.name}
              </Text>
            </Link>
          )}
        </>
      )}
    </>
  );
}

export default function BreadcrumbNavigator({ items = [] }: { items: IBreadcrumbNavigatorItem[] }) {
  return (
    <Breadcrumb>
      <BreadcrumbList>
        {items.map((item) => (
          <Fragment key={item.id}>
            <BreadcrumbItem>
              <BreadcrumbNavigatorItem item={item} />
            </BreadcrumbItem>
            <BreadcrumbSeparator>
              <Slash />
            </BreadcrumbSeparator>
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
