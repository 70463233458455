import { ButtonColors } from '../../constants/ButtonColors';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
} from './AlertDialog';

export interface IConfirmDialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
  title: string;
  description: string;
  actionText: string;
  actionColor?: ButtonColors;
  onAction?: React.MouseEventHandler<HTMLButtonElement>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  asChild?: boolean;
}
/** @deprecated Use Confirm.tsx that has is managed globally instead */
export default function ConfirmDialogLegacy({
  open,
  onOpenChange,
  defaultOpen,
  title,
  description,
  actionText,
  actionColor = ButtonColors.Red,
  onAction,
  onCancel,
  children,
  asChild = false,
}: IConfirmDialogProps) {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange} defaultOpen={defaultOpen}>
      {children && <AlertDialogTrigger asChild={asChild}>{children}</AlertDialogTrigger>}
      <AlertDialogContent>
        <AlertDialogTitle>{title}</AlertDialogTitle>
        <AlertDialogDescription>{description}</AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogAction color={actionColor} onClick={onAction}>
            {actionText}
          </AlertDialogAction>
          <AlertDialogCancel onClick={onCancel}>Cancel</AlertDialogCancel>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
