import { useState } from 'react';
import { UserPlusIcon } from 'lucide-react';
import Button from '../../shared/components/buttons/Button';
import ButtonGroup from '../../shared/components/buttons/ButtonGroup';
import { CardList } from '../../shared/components/cards/CardList';
import ConfirmDialogLegacy from '../../shared/components/alert-dialog/ConfirmDialogLegacy';
import ScrollBarWrapper from '../../shared/components/scrolling/ScrollBarWrapper';
import { ButtonColors } from '../../shared/constants/ButtonColors';
import { MemberCard } from './MemberCard';
import { IMemberPrivileges } from './models/IMemberPrivileges';
import { IMember } from './models/IMember';
import { useMembersQuery, useRemoveMemberMutation } from './queries/MemberQueries';
import Howto from '../../shared/components/message-popups/HowTo';
import { ResourceType } from '../../shared/model/ResourceType';
import InviteMemberModal from './InviteMemberModal';
import { ResourceIds } from '../../shared/hooks/useEntityManifest';

interface IMemberListProps {
  type: ResourceType;
  resourceIds: ResourceIds;
  tenantId: string;
  privileges: IMemberPrivileges;
  resolveConfirmText: (member: IMember) => string;
  resolveHelpText: () => string;
}

export default function MemberList({
  type,
  resourceIds,
  tenantId,
  privileges,
  resolveConfirmText,
  resolveHelpText,
}: IMemberListProps) {
  const [isConfirmRemoveOpen, setIsConfirmRemoveOpen] = useState(false);
  const [isInviteMemberModalOpen, setInviteMemberModalOpen] = useState(false);
  const [memberToRemove, setMemberToRemove] = useState<IMember | undefined>(undefined);
  const membersQuery = useMembersQuery(type, resourceIds);
  const removeMemberMutation = useRemoveMemberMutation(type);

  const handleRemove = (member: IMember) => {
    setMemberToRemove(member);
    setIsConfirmRemoveOpen(true);
  };

  const handleConfirmDelete = () => {
    if (!memberToRemove) return;

    removeMemberMutation.mutate(
      { member: memberToRemove },
      {
        onSuccess: () => {
          setIsConfirmRemoveOpen(false);
          setMemberToRemove(undefined);
        },
      },
    );
  };

  const handleCancelDelete = () => {
    setIsConfirmRemoveOpen(false);
    setMemberToRemove(undefined);
  };

  return (
    <div className="flex flex-col h-full overflow-y-hidden">
      {privileges?.canInviteMember && (
        <ButtonGroup>
          <Button
            onClick={() => setInviteMemberModalOpen(true)}
            icon={<UserPlusIcon className="h-4 w-4 inline-block pr-1" aria-hidden="true" />}
            color={ButtonColors.White}
            text="Invite members"
          />
        </ButtonGroup>
      )}
      <ScrollBarWrapper>
        <CardList>
          {membersQuery.data?.map((member) => (
            <MemberCard
              key={member.userId}
              member={member}
              isOwner={tenantId === member.userId}
              privileges={{ ...privileges }}
              onRemove={() => handleRemove(member)}
            />
          ))}
        </CardList>
      </ScrollBarWrapper>
      <ConfirmDialogLegacy
        open={isConfirmRemoveOpen}
        onOpenChange={setIsConfirmRemoveOpen}
        title="Remove member"
        description={resolveConfirmText(memberToRemove as IMember)}
        actionText="Remove"
        onAction={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />

      <InviteMemberModal
        onOpenChange={setInviteMemberModalOpen}
        type={type}
        resourceIds={resourceIds}
        open={isInviteMemberModalOpen}
      />

      <div className="flex pt-2">
        <Howto title="Members" description={resolveHelpText()} />
      </div>
    </div>
  );
}
