import ProjectCreateEditModal from './ProjectCreateEditModal';
import useModals from '../../shared/components/modal/hooks/useModal';
import AddButton from '../../shared/components/buttons/AddButton';

export function ProjectActionButtons() {
  const { openModal } = useModals();

  return (
    <AddButton
      text="Add project"
      onClick={() =>
        openModal({
          title: 'Create new project',
          content: <ProjectCreateEditModal />,
        })
      }
    />
  );
}
