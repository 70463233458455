import { useState } from 'react';
import { useNavigate } from 'react-router';
import BoardCreateModal from './BoardCreateModal';
import AddButton from '../../shared/components/buttons/AddButton';

export function BoardActionButtons({ projectId }: { projectId: string }) {
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleCreateBoardSuccess = (boardId: string) => {
    navigate(`boards/${boardId}`);
  };

  return (
    <>
      <BoardCreateModal
        projectId={projectId}
        open={isEditModalOpen}
        onOpenChange={setEditModalOpen}
        onSuccess={handleCreateBoardSuccess}
      />
      <AddButton text="Add pipeline" onClick={() => setEditModalOpen(true)} />
    </>
  );
}
