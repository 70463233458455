import { useNavigate } from 'react-router';
import { usePipelineQuery } from './queries/pipelineQueries';
import { usePipelineStoreContext } from './context/pipeline-context';
import TabHeaderWrapper from '../../shared/components/tabs/TabHeaderWrapper';
import TabContent from '../../shared/components/tabs/TabContent';
import TabNavItem from '../../shared/components/tabs/TabNavItem';
import { useStagesQuery } from './queries/stageQueries';
import SidePanelHeadline from '../../shared/components/layout/building-blocks/SidePanelHeadline';
import PipelineEditStages from './PipelineEditStages';
import PipelineEditBudget from './PipelineEditBudget';
import TabWrapper from '../../shared/components/tabs/TabWrapper';
import TabContentWrapper from '../../shared/components/tabs/TabContentWrapper';
import PipelineMembers from './PipelineMembers';
import PipelineModals from './PipelineModals';
import { usePipelinePrivilegesQuery } from './queries/pipelinePriviligesQueries';
import Howto from '../../shared/components/message-popups/HowTo';
import pipelineDummy from '../../assets/images/pipeline-icon.svg';
import useEntityManifest from '../../shared/hooks/useEntityManifest';
import { ResourceType } from '../../shared/model/ResourceType';
import { useBoardQuery } from '../Projects/queries/boardQueries';
import HeaderSubHeader from '../../shared/components/headers/HeaderSubHeader';
import SpacingContainer from '../../shared/components/spacing/SpacingContainer';
import SpacingContainerItem from '../../shared/components/spacing/SpacingContainerItem';
import { Filler } from '../../shared/components/layout/Filler';
import Messaging from '../Messaging/Messaging';
import { useMessagesQuery, UseMessagesQueryResult } from '../Messaging/queries/messageQueries';
import Attachments from '../../shared/components/attachments/Attachments';
import { useResourceConversationQuery } from '../Messaging/queries/conversationQueries';
import Activities from '../Activities/Activities';
import useBoardActions from '../Projects/hooks/useBoardActions';
import TabDropdownItem from '../../shared/components/tabs/TabDropdownItem';

interface IPipelineEditPipelineProps {
  projectId: string;
  boardId: string;
  pipelineId: string;
}

export default function PipelineEditPipeline({
  projectId,
  boardId,
  pipelineId,
}: IPipelineEditPipelineProps) {
  const { navigateToOverview, activeEditPipelineTab, setActiveEditPipelineTab } =
    usePipelineStoreContext();
  const boardQuery = useBoardQuery(projectId, boardId);
  const pipelineQuery = usePipelineQuery(pipelineId);
  const privilegesQuery = usePipelinePrivilegesQuery(pipelineId);
  const stagesQuery = useStagesQuery({ pipelineId });
  const pipelineManifest = useEntityManifest(ResourceType.Pipeline);
  const conversationQuery = useResourceConversationQuery(boardId, ResourceType.Board);
  const conversation = conversationQuery.data;
  const messagesQuery = useMessagesQuery(conversationQuery.data?.id);
  const navigate = useNavigate();

  const handleNavigateBack = () => {
    navigate('..', { replace: true });
  };

  const menuItems = useBoardActions(projectId, boardId, handleNavigateBack, handleNavigateBack);

  if (
    !pipelineQuery.isSuccess ||
    !stagesQuery.isSuccess ||
    !privilegesQuery.isSuccess ||
    !boardQuery.isSuccess
  ) {
    return <></>;
  }

  const board = boardQuery.data;
  const pipeline = pipelineQuery.data;

  return (
    <>
      <SidePanelHeadline
        headline={board.name}
        subHeadline=""
        colorSetting={pipelineManifest.color}
        imageSrc={board?.photoUrl || pipelineDummy}
      />

      <TabWrapper>
        <TabHeaderWrapper>
          <TabNavItem
            id="tab_info"
            activeId={activeEditPipelineTab}
            title="Info"
            setActiveTab={() => setActiveEditPipelineTab('tab_info')}
          />
          {privilegesQuery.data.canReadMembers && (
            <TabNavItem
              id="tab_chat"
              activeId={activeEditPipelineTab}
              title="Chat"
              setActiveTab={() => setActiveEditPipelineTab('tab_chat')}
            />
          )}
          {privilegesQuery.data.canReadMembers && (
            <TabNavItem
              id="tab_members"
              activeId={activeEditPipelineTab}
              title="Members"
              setActiveTab={() => setActiveEditPipelineTab('tab_members')}
            />
          )}
          <TabNavItem
            id="tab_stages"
            activeId={activeEditPipelineTab}
            title="Stages"
            setActiveTab={() => setActiveEditPipelineTab('tab_stages')}
          />
          {privilegesQuery.data.canReadActivities && (
            <TabNavItem
              id="tab_activities"
              activeId={activeEditPipelineTab}
              title="Tasks"
              setActiveTab={() => setActiveEditPipelineTab('tab_activities')}
            />
          )}
          {privilegesQuery.data.canReadBudgets && (
            <TabNavItem
              id="tab_budget"
              activeId={activeEditPipelineTab}
              title="Budget"
              setActiveTab={() => setActiveEditPipelineTab('tab_budget')}
            />
          )}
          <TabDropdownItem isActive={false} menuItems={menuItems.items} />
        </TabHeaderWrapper>
        <TabContentWrapper>
          <TabContent id="tab_info" activeTabId={activeEditPipelineTab}>
            <div className="flex-1 min-h-0 flex flex-col sm:px-0">
              <SpacingContainer>
                <SpacingContainerItem>
                  <HeaderSubHeader
                    size="small"
                    text="Description"
                    subText={board.description || ''}
                  />
                </SpacingContainerItem>
                <SpacingContainerItem>
                  <HeaderSubHeader
                    size="small"
                    text="Currency"
                    subText={pipeline.currencyIsoCode || ''}
                  />
                </SpacingContainerItem>
              </SpacingContainer>
              <Filler />
              <div className="flex pt-2">
                <Howto
                  title="Info"
                  description="Change the name, description and currency of your pipeline."
                />
              </div>
            </div>
          </TabContent>
          <TabContent id="tab_chat" activeTabId={activeEditPipelineTab}>
            {conversation && (
              <Messaging
                messages={messagesQuery as UseMessagesQueryResult}
                conversation={conversation}
              />
            )}
          </TabContent>
          <TabContent id="tab_attachments" activeTabId={activeEditPipelineTab}>
            <Attachments conversationId={conversation?.id as string} />
          </TabContent>
          <TabContent id="tab_activities" activeTabId={activeEditPipelineTab}>
            <Activities resourceIds={[projectId, boardId]} type={ResourceType.Board} />
          </TabContent>
          <TabContent id="tab_members" activeTabId={activeEditPipelineTab}>
            <PipelineMembers projectId={projectId} boardId={boardId} pipelineId={pipelineId} />
          </TabContent>
          <TabContent id="tab_stages" activeTabId={activeEditPipelineTab}>
            <PipelineEditStages />
          </TabContent>
          <TabContent id="tab_budget" activeTabId={activeEditPipelineTab}>
            <PipelineEditBudget />
          </TabContent>
          <PipelineModals
            projectId={projectId}
            boardId={boardId}
            pipelineId={pipelineId}
            onDelete={() => navigateToOverview()}
            onLeave={() => navigateToOverview()}
          />
        </TabContentWrapper>
      </TabWrapper>
    </>
  );
}
