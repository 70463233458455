import { Fragment, ReactNode } from 'react';
import { IActionItem } from '../../model/IActionItem';
import {
  DropdownMenuBadge,
  DropdownMenuDivider,
  DropdownMenuIcon,
  DropdownMenuItem,
  DropdownMenuShortcut,
} from './DropdownMenu';
import MenuItemActionButton from '../menus/MenuItemActionButton';

export function renderDropdownMenuItems(menuItems: IActionItem[]) {
  const anyIcon = menuItems.some(({ icon }) => icon);
  return menuItems.map(
    ({ id, icon, title, shortcut, useDivider, badgeCount, disabled, ...props }) => (
      <Fragment key={id}>
        <DropdownMenuItem inset={anyIcon && !icon} {...props} disabled={disabled}>
          {icon && <DropdownMenuIcon icon={icon} />}
          {title}
          {shortcut && <DropdownMenuShortcut>{shortcut}</DropdownMenuShortcut>}
          {!shortcut && <DropdownMenuBadge count={badgeCount} />}
        </DropdownMenuItem>

        {useDivider && <DropdownMenuDivider />}
      </Fragment>
    ),
  );
}

export function renderMenuItems(menuItems: IActionItem<ReactNode>[]) {
  return menuItems.map(({ id, icon, title, useDivider, disabled, onClick }) => (
    <MenuItemActionButton
      key={id}
      text={title}
      icon={icon}
      disabled={disabled}
      useDivider={useDivider}
      onClick={onClick}
    />
  ));
}
